const VirtualRoomFields = [
    {
        "FieldName": "Name",
        "FieldType": "bigint",
        "FieldTitle": "Name",
        "Position": 1,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdCustomerType",
        "FieldType": "bigint",
        "FieldTitle": "Customer Type",
        "Position": 2,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "IdCountry",
        "FieldType": "bigint",
        "FieldTitle": "Country",
        "Position": 3,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "IdParent",
        "FieldType": "bigint",
        "FieldTitle": "Parent",
        "Position": 4,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "IdCountryState",
        "FieldType": "bigint",
        "FieldTitle": "State",
        "Position": 5,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "Address1",
        "FieldType": "bigint",
        "FieldTitle": "Address 1",
        "Position": 6,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "Address2",
        "FieldType": "bigint",
        "FieldTitle": "Address 2",
        "Position": 7,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "City",
        "FieldType": "bigint",
        "FieldTitle": "City",
        "Position": 8,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "ZipCode",
        "FieldType": "bigint",
        "FieldTitle": "Zip Code",
        "Position": 9,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "WebSite",
        "FieldType": "bigint",
        "FieldTitle": "Web Site",
        "Position": 10,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },


]

export const RoomFilterFields = [
    {
        "FieldName": "Description",
        "FieldType": "varchar",
        "FieldTitle": "Description",
        "cssClasss": "col-6 col-md-4",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "FieldName": "Code",
        "FieldType": "varchar",
        "FieldTitle": "Code",
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdBuilding",
        "FieldType": "bigint",
        "FieldTitle": "Building",
        "cssClasss": "col-6 col-md-3",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": '{"HashID":"d373bb8a3e97f3e5c2ab80d8a82bf83c1db0463cc440bf5a00995fcb0706bbf3"}',
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdBuildingFloor",
        "FieldType": "bigint",
        "FieldTitle": "Building Floor",
        "cssClasss": "col-6 col-md-3",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": '{"HashID":"e2258e57a645a2e471803b3cf8997c4d318c86812e491072f27a883deee2a2ab"}',
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "idRoomType",
        "FieldType": "bigint",
        "FieldTitle": "Room Type",
        "cssClasss": "col-6 col-md-3",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": '{"HashID":"c8cd1c30a5cd65d35b2dc5b4c0287d131a741f68a85fd9828e4216931f6ff384"}',
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdRoomView",
        "FieldType": "bigint",
        "FieldTitle": "Room View",
        "cssClasss": "col-6 col-md-3",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": '{"HashID":"89f621b8ee4a8b3941f3f355eb4416e30ca0f13b791869839303a1fbcfefcbb8"}',
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdRoomConnecting",
        "FieldType": "bigint",
        "FieldTitle": "Room Connecting",
        "cssClasss": "col-6 col-md-3",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdBusinessUnit",
        "FieldType": "bigint",
        "FieldTitle": "Business Unit",
        "cssClasss": "col-6 col-md-3",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": '{"HashID":"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
]

export default VirtualRoomFields;