import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import API from "../../api/api";
import { CustomBreadcrumbs as Breadcrumbs } from '../../components';
import RoomMapContainer from "../../components/room-map/RoomMapContainer";
import RoomMapFilter from "../../components/room-map/RoomMapFilter";
import { useToast } from "../../contexts/toast";
import { handleFilter } from '../../utils/filter-utils';
import { parsedUser } from "../../utils/GetCurrentUser";
import { RoomMapFilterFields } from "./FilterFields";
import { useTranslation } from "react-i18next";

const RoomMapPage = () => {
  const user = parsedUser();
  const toast = useToast();
  const [records, setRecords] = useState([]); //This will hold the data from the database
  const [isLoading, setIsLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    bindDataRemote();
  },[])

  const bindDataRemote = async (filter) => {
    setShowReport(false);
    setIsLoading(true);
    try {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: filter ?? `@IdBusinessUnit=${user.IdBusinessUnit}`,
      };
      let request = await API.postAction(
        "/api/Rooms/RoomMap?" + queryString,
        queryData
      );
      // console.log("🚀 ~ bindDataRemote ~ request:", request.data)

      let results = request.data[0].RoomMapJson;
      if (results === null) {
        toast({
          title: "No data found",
          status: "error",
        })
        throw new Error("No data found");
      }
      console.log("🚀 ~ bindDataRemote ~ results:", results)
      let parsedResults = JSON.parse(results);
      // console.log("🚀 ~ bindDataRemote ~ parsedResults:", parsedResults.RoomMap)
      setRecords(parsedResults.RoomMap);
      if (parsedResults === null) {
        toast({
          title: "No data found",
          status: "error",
        })
        throw new Error("No data found");
      }
      setRecords(parsedResults.RoomMap);
      setShowReport(true);
    } catch (error) {
      toast({
        type: "error",
        message: error.message || "An error occurred",
        width: "auto",
        height: "auto",
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submitFilter = async (obj) => {
    const ObjVersion = await handleFilter(obj, RoomMapFilterFields);
    await bindDataRemote(ObjVersion);
  };

  const clearReport = () => {
    try {
      setShowReport(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <Breadcrumbs pathTitle={'Front-Desk / Room Map'} />
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className="fa-solid fa-home me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Room Map</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
              </div>
            </div>
            <RoomMapFilter
              // count={recordCount}
              handleFilter={(obj) => submitFilter(obj)}
              isLoading={isLoading}
              clearReport={clearReport}
            />
            {showReport && <RoomMapContainer records={records} />}
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default RoomMapPage
