import { Button, Card, Col, Row } from 'react-bootstrap';
import { getIconsByType } from './RoomOverviewTableDetails';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import RoomOverviewChangeStatusModal from './RoomOverviewChangeStatusModal';

const RoomOverviewCardList = ({ records, reload, isLoading }) => {
  const { t } = useTranslation();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [show, setShow] = useState(false);

  // Extract unique GroupColumn values to create dynamic columns
  const uniqueGroupColumns = Array.from(new Set(records.map(item => item.GroupColumn)));

  const COLUMNS = uniqueGroupColumns
  .sort((a, b) => a.localeCompare(b))
  .map((groupColumn) => ({
    id: groupColumn,
    title: groupColumn,
  }));

  const handleCheckboxChange = (room) => {
    setSelectedRooms((prevSelected) => {
      // Check if the room is already selected
      if (prevSelected.some((selected) => selected.IdRoom === room.IdRoom)) {
        // If it's already selected, remove it from the state
        return prevSelected.filter((selected) => selected.IdRoom !== room.IdRoom);
      } else {
        // If it's not selected, add it to the state
        return [...prevSelected, room];
      }
    });
  };

  const closeChangeStatusModal = () => {
    setShow(false);
    reload();
  }

  useEffect(() => {
    setSelectedRooms([]);
  }, [records]);

  return (
    <>
      <RoomOverviewChangeStatusModal show={show} handleClose={closeChangeStatusModal} roomList={selectedRooms} />
      <Card>
        <Card.Body>
          <Row className='mb-4'>
            <Col className='d-flex justify-content-end'>
              <Button
                variant="primary"
                onClick={() => setShow(true)}
                disabled={selectedRooms.length === 0}
              >{t("Change Status")}</Button>
            </Col>
          </Row>
          <Row className='mb-4'>
            {isLoading
              ? (
                <div className="d-flex justify-content-center my-2">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">
                      {t("Loading")}...
                    </span>
                  </div>
                </div>
              )
              : records.length === 0
                ? (
                  <div className="w-25 mx-auto my-1">
                    <div className="text-center">
                      <h5>{t("No records found")}</h5>
                    </div>
                  </div>
                ) : <Col className='d-flex' style={{ overflow: 'auto', width: '100vw' }}>
                  {COLUMNS.map((column) => (
                    <>
                      <BuildingColumn title={column.title} room={records} key={column.id} handleCheckboxChange={handleCheckboxChange} selectedRooms={selectedRooms} />
                    </>
                  ))}
                </Col>
            }
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}


export default RoomOverviewCardList

const BuildingColumn = ({ title, room = [], handleCheckboxChange, selectedRooms }) => {
  const [roomList, setRoomList] = useState(room.filter((room) => room.GroupColumn === title));

  useEffect(() => {
    setRoomList(room.filter((room) => room.GroupColumn === title));
  }, [room, title]);

  return (
    <Card className="bg-secondary bg-body-secondary pb-4 mx-2" style={{ minWidth: '300px' }}>
      <Card.Header className='text-center bg-dark text-white fw-bold' as={'h5'}>{title}</Card.Header>
      <Card.Body style={{maxHeight: '525px', overflowY:'auto'}}>
        {
          roomList.map((room) => (
            <RoomCard room={room} key={room.IdRoom} handleCheckboxChange={handleCheckboxChange} selectedRooms={selectedRooms} />
          ))
        }
      </Card.Body>
    </Card>
  );
};

const RoomCard = ({ room, selectedRooms, handleCheckboxChange }) => {
  // room.Arrival = true
  // room.Departure = true
  // room.Stayover = true
  return (
    <Card className='my-2' style={{backgroundColor: room.BackgroundColor}}>
      <Card.Body className='p-0'>
        <Row className='align-items-baseline'>
          <Col className='d-flex justify-content-center align-content-center' xs={2}>
            <input
              type="checkbox"
              className="form-check-input"
              onChange={() => handleCheckboxChange(room)}
              checked={selectedRooms.some((selected) => selected.IdRoom === room.IdRoom)}
            />
          </Col>
          <Col className='d-flex' xs={6}>
            <p style={{color: room.TextColor, fontSize: '16px'}}>{room.Room}</p>
          </Col>
          <Col className='d-flex' xs={4}>
            {getIconsByType(room).map((icon, index) => (
              <i key={index} className={`fs-4 mx-2 ${icon}`} style={{color: room.TextColor}} />
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}