import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { USDollar } from '../../../utils/number-to-currency';
import PrintInvoice from './PrintInvoice';

const PaymentAppliedModal = ({ baseCurrency, show, close, detailedAmounts, handleClose, GUIDBillingDocument, selectedReservation }) => {

  const [showPrintInvoice, setShowPrintInvoice] = useState(false);

  const togglePrintInvoice = (obj) => {
    setShowPrintInvoice(!showPrintInvoice);
    handleClose();
  };

  // useEffect(() => {
  //   if (show) {
  //     console.log("🚀 ~ PaymentAppliedModal ~ GUIDBillingDocument", GUIDBillingDocument)
  //   }
  // },[])

  return (
    <>
    {
      <PrintInvoice
        show={showPrintInvoice}
        toggle={togglePrintInvoice}
        selectedFolioDetails={{GUIDBillingDocument}}
        selectedReservation={selectedReservation}
      />
    }
    <Modal
      show={show}
      size="sm"
      onHide={handleClose}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Payment Applied</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col xs={12} className="mb-3">
          <div>
            <label className="me-2 mt-2">
              Total Amount {baseCurrency?.Currency}
            </label>
            <br />
            <input
              type="text"
              className="form-control fw-bolder text-xl"
              value={USDollar(detailedAmounts?.totalAmount)}
              disabled
            ></input>
          </div>
          <div>
            <label className="me-2 mt-2">
              Total Amount Paid {baseCurrency?.Currency}
            </label>
            <br />
            <input
              type="text"
              className="form-control fw-bolder"
              value={USDollar(detailedAmounts?.totalAmountPaid)}
              disabled
            ></input>
          </div>
          <div>
            <label className="me-2 mt-2">
              Change Due {baseCurrency?.Currency}
            </label>
            <br />
            <input
              type="text"
              className={`${detailedAmounts?.changeDue < 0 ? " text-danger" : ""} form-control fw-bolder`}
              value={USDollar(detailedAmounts?.changeDue)}
              disabled
            ></input>
          </div>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={handleClose}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => setShowPrintInvoice(true)}>
          <i className="fas fa-print me-2"></i>
          Print
        </button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default PaymentAppliedModal;