import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import HeaderTasksAssignmentPlanning from "./HeaderTasksAssignmentPlanning";
import CollaboratorsRoomsAssignment from "../../Component/CollaboratorsRoomsAssignment";
import API from "../../../../api/api";
import { parsedUser } from "../../../../utils/GetCurrentUser";

const TasksAssignmentPlanning = ({
  show,
  toggle,
  selectedItem,
  action,
  title,
}) => {
  const [tasksDetail, setTasksDetail] = useState([]);
  const [tasksCard, setTasksCard] = useState([]);

  const user = parsedUser();

  const updateTaskCard = async () => {
    if (selectedItem) {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@GUIDAutomaticTaskAssignment="${selectedItem.GUID}", @IdAutomaticTasksAssignment=${selectedItem.Id}`,
      };
      let requestAPI = await API.postAction(
        "/api/AutomaticTasksAssigments/GroupAssignationDetails?" + queryString,
        queryData
      );

      console.log(requestAPI);
    }
  };

  console.log(tasksDetail);
  useEffect(() => {
    updateTaskCard();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        backdrop="static"
        keyboard={false}
        className="modalRight"
        dialogClassName="modal-90w"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Task Assignment Planning - {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12}>
                <HeaderTasksAssignmentPlanning
                  getTasksDetails={setTasksDetail}
                  getTasksCard={setTasksCard}
                  taskSelected={selectedItem}
                  action={action}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <Tabs defaultActiveKey={"taskAssignmentOverview"}>
                  <Tab
                    eventKey={"taskAssignmentOverview"}
                    title={"Task Assignment Overview"}
                  >
                    <Table bordered hover>
                      <thead>
                        <tr className="text-center">
                          <th>Collaborator</th>
                          <th>Total Rooms</th>
                          <th>Arrival Rooms</th>
                          <th>Stayover Rooms</th>
                          <th>Departure Rooms</th>
                          <th>Total Adults</th>
                          <th>Total Children</th>
                          <th>Total Guest</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tasksDetail && tasksDetail.length > 0 ? (
                          tasksDetail.map((item, index) => {
                            return (
                              <>
                              <tr key={index} className="text-center">
                                <td>{item.TaskNumber}</td>
                                <td>{item.TotalRooms}</td>
                                <td>{item.ArrivalRooms}</td>
                                <td>{item.StayoverRooms}</td>
                                <td>{item.DepartureRooms}</td>
                                <td>{item.TotalAdults}</td>
                                <td>{item.TotalChildren}</td>
                                <td>{item.TotalGuest}</td>
                              </tr>
                              { index === tasksDetail.length - 1 &&
                                <tr>
                                <td className="text-center fw-bolder">Totals</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.TotalRooms, 0)}</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.ArrivalRooms, 0)}</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.StayoverRooms, 0)}</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.DepartureRooms, 0)}</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.TotalAdults, 0)}</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.TotalChildren, 0)}</td>
                                <td className="text-center fw-bolder">{tasksDetail.reduce((acc, curr) => acc + curr.TotalGuest, 0)}</td>
                              </tr>}
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="border-1 text-center" colSpan={8}>
                              No Records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab
                    eventKey={"housekeepingTaskSheet"}
                    title={"Housekeeping Task Sheet"}
                  >
                    <div className="mt-3 px-0 w-100">
                      {tasksCard && tasksCard.length > 0 ? (
                        <CollaboratorsRoomsAssignment
                          tasksDetail={tasksDetail}
                          tasksCard={tasksCard}
                        />
                      ) : (
                        <div className=" text-center fs-bolder">
                          No Collaborators
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TasksAssignmentPlanning;
