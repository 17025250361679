import { useEffect, useCallback } from "react";

const usePreventUnload = (shouldPreventUnload, onUnloadCallback) => {
  const handleBeforeUnload = useCallback(
    (event) => {
      if (!shouldPreventUnload) return; // Prevent immediate closure by showing a confirmation alert
      event.preventDefault();
      event.returnValue = ""; // Necessary to show the default browser message
    },
    [shouldPreventUnload]
  );

  const handleUnload = useCallback(async () => {
    if (!shouldPreventUnload) return; // Prevent immediate closure by showing a confirmation alert
    if (onUnloadCallback) {
      await onUnloadCallback(); // Execute the callback function
    }
  }, [shouldPreventUnload, onUnloadCallback]);

  useEffect(() => {
    // Agregar eventos
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      // Limpiar eventos al desmontar el componente
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [handleBeforeUnload, handleUnload]);
};

export default usePreventUnload;
