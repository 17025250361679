import RestaurantFilter from "./RestaurantFilter";
import ReservationDetailsContainer from "./reservationDetails/ReservationDetailsContainer";
import React, { useEffect, useState } from "react";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import CustomBreadcrumbs from "../breadcrumb";
import AvailabilityPreview from "./AvailabilityPreview";
import API from "../../api/api";
import { RestaurantReservationTableFields } from "./fields/RestaurantReservationTable";
import { parsedUser } from "../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";
import { confirmAlert } from "react-confirm-alert";
import { Button, Card, Container } from "react-bootstrap";

const RestaurantReservationContainer = ({ routeName }) => {
  const [actionMode, setActionMode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data
  const [recordCount, setRecordCount] = useState(0);
  const [records, setRecords] = useState([]);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAvailabilityPreview, setShowAvailabilityPreview] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [sortBy, setSortBy] = useState("ReservationNumber");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [tableHeader, setTableHeader] = useState(RestaurantReservationTableFields);
  const [totalPages, setTotalPages] = useState(1);
  const user = parsedUser();

  const bindDataRemote = async (filter) => {
    if (isLoading === true) return;
    // First start loading the model
    setIsLoading(true);

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: filter,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/RestaurantReservation/Records?` + query,
        queryData ?? ""
      );
      // console.log(GetDataOfJSON(request, "data", 0, "JSONData", 0));
      let results = GetDataOfJSON(request, "data", 0, "JSONData", 0);

      let recordResult = results.JSONData || [];

      setRecords(recordResult);

      // console.log("model", model)
      await bindDataCountRemote(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataCountRemote = async (filter) => {
    // First start loading the model
    // setLoading(true);

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(
        `api/RestaurantReservation/Count?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let query = `userId=${user.IdUser}&token=${user.Token}&RecordId=${selectedItem.GUID}`;
            await API.getAction("api/RestaurantReservation/Delete", query);
            setSelectedItem(null);
            await bindDataRemote();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const principalButtonList = [
    {
      id: "edit",
      title: "Edit",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "B"],
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: "new",
      title: "New",
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleAddEdit("I"),
    },
    {
      id: "cancel",
      title: "Cancel",
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-times",
      ifSelectedItem: true,
      reservationCode: ["T", "C"],
      onClick: () => deleteConfirm(),
    },
  ];

  const handleClosePreview = (status) => {
    setShowAvailabilityPreview(status);
  };

  const toggleAddEdit = (action) => {
    if (action === "I") {
      setSelectedItem(null);
    }
    setActionMode(action);
    setShowDetails(!showDetails);
    if (showDetails) {
      setSelectedItem(null);
      bindDataRemote();
    }
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    setCurrentPage(1);
    await bindDataRemote(obj);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(recordCount / RecordsQuantity));
  }, [recordCount, RecordsQuantity]);

  useEffect(() => {
    bindDataRemote();
    setSelectedItem(null);
  }, [sortBy, sortByOrder, RecordsQuantity, currentPage]);

  return (
    <>
      {showAvailabilityPreview ? (
        <AvailabilityPreview
          show={showAvailabilityPreview}
          handleClose={handleClosePreview}
        />
      ) : null}

      {showDetails ? (
        <ReservationDetailsContainer
          show={showDetails}
          toggle={toggleAddEdit}
          actionMode={actionMode}
          selectedReservation={selectedItem}
        />
      ) : null}

      <CustomBreadcrumbs pathTitle={routeName} />
      <Container className="mt-0 pt-0">
        {/* // <IncidentFilter handleClose={closeFilter} />} */}
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>

                  <div
                    className="w-25 mx-auto my-1"
                    style={{ paddingTop: "" }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>

                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="dx-icon fas fa-utensils me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{"Restaurant Reservation"}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div
              className="ps-3 py-2 z-2"
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#f2f2f2",
              }}
            >
              <div className="d-flex w-100 my-2 mt-0 justify-content-start gap-1">
                {/* {console.log(selectedItem)} */}
                <Button
                  variant={'outline-primary'}
                  size="lg"
                  className={''}
                  onClick={() => {
                    toggleAddEdit('I');
                  }}
                >
                  New
                  <br />
                  <i className={'fa-solid fa-plus'} />
                </Button>
                {selectedItem !== null && principalButtonList.filter(x => x.ifSelectedItem).map(btn => {
                  let item = btn;
                  if (item.ifSelectedItem && selectedItem) {
                    return (
                      <Button
                        key={item}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    );
                  }
                })}
              </div>
            </div>
            <RestaurantFilter
              count={recordCount}
              isLoading={isLoading}
              handleFilter={(obj) => handleFilter(obj)}
            />
            <Card>
              <Card.Body>
                <div className="w-100">
                  <div className="wrapper" style={{ overflowY: "auto" }}>
                    <table className="table table-bordered shadow-sm table-striped">
                      <thead>
                        <tr>
                          {headerWithoutAuditFields().map((item, index) => {
                            return (
                              <th
                                key={index}
                                className={`fw-bold clickable`}
                                onClick={() => {
                                  setSortBy(item.FieldName);
                                  if (item.FieldName === sortBy) {
                                    if (sortByOrder === "ASC") {
                                      setSortByOrder("DESC");
                                    } else {
                                      setSortByOrder("ASC");
                                    }
                                  } else {
                                    setSortByOrder("ASC");
                                  }
                                }}
                              >
                                {item.FieldTitle}{" "}
                                {sortBy === item.FieldName &&
                                  sortByOrder === "ASC" ? (
                                  <i className="fas fa-sort-up"></i>
                                ) : sortBy === item.FieldName &&
                                  sortByOrder === "DESC" ? (
                                  <i className="fas fa-sort-down"></i>
                                ) : null}{" "}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {records && records.length > 0 ? (
                          records.map((item, index) => {
                            // if (item.RestaurantsSeattings) {
                            //   console.log(moment(item?.RestaurantsSeattings, "HH:mm:ss").format("hh:mm A"));
                            //   // item.RestaurantsSeattings = moment(item.RestaurantsSeattings).format('hh:mm A')
                            // }
                            return (
                              <tr
                                key={index}
                                className={` clickable ${item === selectedItem ? " active" : ""
                                  }`}
                                onClick={() => setSelectedItem(item)}>
                                <td className="text-center">{item.ReservationNumber}</td>
                                <td>{item.Restaurant}</td>
                                <td>{item.ReservationState}</td>
                                <td className="text-nowrap">
                                  {item.ReservationDate
                                    ? moment(item.ReservationDate).format(
                                      "MM/DD/YYYY"
                                    )
                                    : null}
                                </td>
                                <td>{moment(item?.RestaurantsSeattings, "HH:mm:ss").format("hh:mm A")}</td>
                                <td className="text-center">{item.PaxQuantity}</td>
                                <td>{item.Room}</td>
                                <td>{item.GuestName}</td>
                                <td>{item.Comment}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="border-1 text-center" colSpan={12}>
                              No Records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      isLoading={isLoading}
                      RecordsQuantity={RecordsQuantity}
                      recordCount={recordCount}
                      // totalPages={totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      setRecordsQuantity={setRecordsQuantity}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RestaurantReservationContainer;
