import React, { useCallback, useEffect, useRef, useState } from "react";
import { Search, CleaningServices } from "@mui/icons-material";
import "devextreme/data/odata/store";

import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";
import { db } from "../../utils/db";
import { useToast } from "../../contexts/toast";
import { utils as SheetUtils, writeFile as writeExcel } from "xlsx";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TextBox } from "devextreme-react";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CRUDPageGRID from "./CRUDPageGRID";
import CRUDPageFORM from "./CRUDPageFORM";
import yupTypeValidator from "../../utils/YupTypeValidator";

import { useTranslation } from "react-i18next";

const CRUDMaster = () => {
  // PROPERTIES
  const { t } = useTranslation();

  const navigate = useNavigate();
  const toast = useToast();

  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState("0");
  const [auditFields, setAuditFields] = useState([]);
  const formRef = useRef();
  const user = parsedUser();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const initialModel = {
    section: {},
    data: {
      Rows: [],
      Columns: [],
    },
  };
  const [model, setModel] = useState(initialModel);
  const [childModel, setChildModel] = useState(null);
  const [childFields, setChildFields] = useState(null);
  const [childErrors, setChildErrors] = useState(null);
  const [showAudit, setShowAudit] = useState(false);
  // const memoModel = useMemo(() => model, [model]);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [8, 12, 20, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const selectedItemRef = useRef(null);
  const checkAudit = localStorage.getItem("AuditInformation");

  const [requestObject, setRequestObject] = useState(false); //1. used to trigger on child component  the request of fields
  const [requiresPageObject, setRequiresPageObject] = useState(false); //We use this to count if we need to have the object of the page

  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (cleanFilter) => {
    // First start loading the model
    setLoading(true);

    //First load records

    try {
      let query = `id=${params.id}&token=${user.Token}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }
      let request = await API.getAction("api/CRUD/Records", query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      let configInfo = recordResult[0].JSONConfig
        ? recordResult[0].JSONConfig[0]
        : null;
      let dataFromAPI = recordResult[0].JSONConfig
        ? recordResult[0].JSONData
        : recordResult;
      console.log("Results from record", recordResult);

      //Now let's load sections
      let querySections = `id=${params.id}&token=${user.Token}`;
      let requestSections = await API.getAction(
        "api/CRUD/Model",
        querySections
      );
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];

      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      //* If there is a config, then we need to sort the fields to show them in the grid
      // if (configInfo && configInfo.Fields) {

      //   const sortedFields = configInfo.Fields.sort((a, b) => a.Position - b.Position);

      //   //? Here I reduce the fields to only the ones that are visible
      //   gridColumns = sortedFields.reduce((acc, item) => {
      //     (item.Visible) && (acc.push(item.FieldName))
      //     return acc;
      //   }, [])

      //   gridData = dataFromAPI.map((item) => {
      //     return gridColumns.reduce((acc, key) => {
      //       acc[key] = item[key];
      //       acc["Id"] = item["Id"];
      //       return acc;
      //     }, {})
      //   })
      // }

      //! If grid data stays null, we use the data from the API
      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];

      setModel({
        ...model,
        section:
          checkAudit === "true"
            ? recordResultSection[0]
            : {
              ...recordResultSection[0],
              Fields: recordResultSection[0].Fields.filter(
                (item) => !titles.includes(item.FieldTitle)
              ),
            },
        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const bindDataDetailsRemote = async (recordId, isOld = true) => {
    //First load records
    let query = `id=${params.id}&token=${user.Token}&RecordId=${recordId}`;
    let request = await API.getAction("api/CRUD/Details", query);
    let results = request.data[0];

    let parsedResults = JSON.parse(results.JSONData)[0];
    if (parsedResults.Error) {
      alert(parsedResults.Msg);
      return;
    }
    let finalResult = parsedResults.JSONData[0];
    fillFormikObject(finalResult);
    if (isOld) setRecordId(finalResult?.GUID);
    //Fill object with new values
  };

  //Open edit/add mode
  const [recordId, setRecordId] = useState("");
  const toggleOpen = async (action, reload) => {
    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null);
      setRecordId(uuidv4());
    }
    if (action === "U" || action === "C") {
      await bindDataDetailsRemote(selectedItem.Id);
    }
    if (action === "D") {
      await bindDataDetailsRemote(selectedItem.Id, false);
      setRecordId(uuidv4());
    }
    if (action === "C") {
      setShowAudit(true);
    }
    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setSelectedTab("0");
    setActionMode(action);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `id=${params.id}&token=${user.Token}&RecordId=${selectedItem.Id}`;
            await API.getAction("api/CRUD/Delete", query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", model.section);
    if (
      model.section &&
      model.section.Fields &&
      model.section.Fields.length > 0
    ) {
      model.section.Fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (model && model.section.Fields && model.section.Fields.length > 0) {
      model.section.Fields.forEach((item) => {
        // console.log(item.FieldName, item);
        if (item.FieldName !== "") {
          initialObject[item.FieldName] = item.DefaultValue
            ? item.DefaultValue
            : item.value;
          if (item.Required) {
            initialObjectValidation[item.FieldName] = yupTypeValidator(item);
          }
        }
      });
      formik.initialValues = initialObject;
      console.log("formik initial value", initialObject);
      setValidationSchema(yup.object(initialObjectValidation));
    }
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    //   event.preventDefault();

    console.log("the object to submit", obj, event);
    if ((requiresPageObject && childModel === null) || childErrors) {
      //We need to stop and request the objects of the page
      setRequestObject(true);
      return;
    }
    //In any other case, just continue

    let fields = [...model.section.Fields];

    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = { ...childModel, ...rest };
    }

    if (actionMode === "I" || actionMode === "D") {
      obj = { ...obj, GUID: recordId };
    }

    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to save this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let objSPVersion = "";
            try {
              if (childFields) {
                fields = [...fields, ...childFields];
              }
              console.log(
                "❗ ~ file: CRUDMaster.js:281 ~ onClick: ~ fields:",
                fields
              );

              fields.forEach((item) => {
                if (
                  item.ReadOnly ||
                  item.HtmlType === "separator" ||
                  item.FieldName === "IdCustomer"
                )
                  return;

                //Filter audit fields
                if (
                  item.FieldTitle === "Audit Information" ||
                  item.FieldName === "IdUserAdd" ||
                  item.FieldName === "IdUserMod" ||
                  item.FieldName === "DateAdd" ||
                  item.FieldName === "DateMod"
                )
                  return;

                if (item.HtmlType !== "separator") {
                  objSPVersion +=
                    (objSPVersion.length > 0 ? ", " : "") +
                    `@${item.FieldName}=`;
                }
                if (
                  item.FieldType === "int" ||
                  item.FieldType === "bool" ||
                  item.FieldType === "bit" ||
                  item.FieldType === "bigint"
                ) {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                      obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0
                        ? item.DefaultValue
                        : "null"
                      : "'" + obj[item.FieldName] + "'";
                  //objSPVersion +=
                  //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
                } else if (item.HtmlType === "password") {
                  if (obj[item.FieldName] === undefined || obj[item.FieldName] === null) {
                    objSPVersion += null;
                    return
                  }
                  let passwordEncrypted = null;
                  if (obj[item.FieldName] && obj[item.FieldName].length > 0 && obj[item.FieldName] !== "null") {
                    passwordEncrypted = obj["UserName"].toUpperCase() + obj[item.FieldName];
                    passwordEncrypted = Sha256.hash(passwordEncrypted);
                  }
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? null
                      : "'" + passwordEncrypted + "'";
                } else if (item.HtmlType === "radio") {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                      obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0
                        ? item.DefaultValue
                        : "null"
                      : "'" + obj[item.FieldName] + "'";

                  // } else if(item.HtmlType==="separator"){
                  //   //DO NOTHING, CONTINUE NEXT RECORD
                  //   debugger;
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined ||
                      obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
              });

              let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}`;
              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                "api/CRUD/AddEdit?" + queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  setGeneralError(response.Msg);
                  return;
                }
              }
              toggleOpen(null, true);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${model.section.FormIcon}`,
      path: pathname,
      formPath: model.section.FormPath,
      title: model.section.FormTitle,
    };

    try {
      await db.favorites
        .where("username")
        .equals(user.Username)
        .modify((item) => {
          const favExists = item.values.some(
            (fav) => fav.path === newFavorite.path
          );

          if (favExists) {
            setIsPinned(false);
            item.values = item.values.filter(
              (fav) => fav.path !== newFavorite.path
            );
            toast({
              type: "warning",
              message: t("Removed from favorites"),
            });
            return;
          }

          setIsPinned(true);
          item.values.push(newFavorite);
          toast({
            type: "success",
            message: t("Added to favorites"),
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const exportToExcel = useCallback(async () => {
    const excelData =
      model.data.Rows &&
      model.data.Rows.map((item) => {
        return model.data.Columns.reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {});
      });

    const worksheet = SheetUtils.json_to_sheet(excelData);

    const workbook = SheetUtils.book_new();

    SheetUtils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeExcel(workbook, `${model.section.FormTitle}.xlsx`);
  }, [model.data.Rows]);

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");

    const containerHeight = container.clientHeight;
    const containerWidth = container.clientWidth;
    const ratio = containerHeight / containerWidth;

    html2canvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("l", "mm", "a0");

      const width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      height = ratio * width;

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(`${model.section.FormTitle}.pdf`);
      pdf.close();
    });
  }, []);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const doSubmit = (obj, fields) => {
    console.log("child obj", obj, formik.values);
    obj.values && setChildModel(obj.values);
    setChildErrors(obj.errors);
    fields && setChildFields(fields);
  };

  // END METHODS

  // UI METHODS
  const loadFormFields = (fields) => {
    let uiFields =
      fields &&
      fields
        .filter(
          (x) =>
            x.FieldTitle !== "Audit Information" &&
            x.FieldName !== "IdUserAdd" &&
            x.FieldName !== "IdUserMod" &&
            x.FieldName !== "DateAdd" &&
            x.FieldName !== "DateMod"
        )
        .map((item, index) => {
          return (
            <Col
              className={item.cssClasss + (!item.Visible ? " d-none" : "")}
              key={index}
            >
              <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
              {item.Required ? (
                <i className="required-asterisk ms-1 text-danger">*</i>
              ) : null}
              <br />

              <FieldSelector
                actionMode={actionMode}
                model={item}
                key={index}
                updateField={updateField}
                value={formik.values[item.FieldName]}
              ></FieldSelector>
              {formik.errors[item.FieldName] ? (
                <div className="invalid text-sm">
                  {t(formik.errors[item.FieldName])}
                </div>
              ) : null}
            </Col>
          );
        });

    return uiFields;
  };

  const loadFormFieldsAudit = (fields) => {
    let uiFields =
      fields &&
      fields
        .filter(
          (x) =>
            x.FieldTitle === "Audit Information" ||
            x.FieldName === "IdUserAdd" ||
            x.FieldName === "IdUserMod" ||
            x.FieldName === "DateAdd" ||
            x.FieldName === "DateMod"
        )
        .map((item, index) => {
          return (
            <Col
              className={item.cssClasss + (!item.Visible ? " d-none" : "")}
              key={index}
            >
              <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
              <br />

              <FieldSelector
                actionMode={"C"}
                model={item}
                key={index}
                updateField={updateField}
                value={formik.values[item.FieldName]}
              ></FieldSelector>
              {formik.errors[item.FieldName] ? (
                <div className="invalid text-sm">
                  {t(formik.errors[item.FieldName])}
                </div>
              ) : null}
            </Col>
          );
        });

    return uiFields;
  };
  const loadFormUI = () => {
    let fields = model.section.Fields;
    let pages = model.section.Pages;
    return (
      <div className="card">
        <Form onSubmit={formik.handleSubmit} ref={formRef}>
          <div className="card-body p-4">
            <Row>
              {/* CONTENT */}
              {loadFormFields(fields)}

              {/* LOAD TABS */}
              <Container fluid id="myTab">
                <Tabs
                  onSelect={(key) => setSelectedTab(key)}
                  className="mt-4 border-bottom-0"
                  role="tablist"
                >
                  {pages &&
                    pages.map((item, index) => {
                      return (
                        <Tab
                          key={index}
                          eventKey={`${index}`}
                          className="bg-light border-1 mb-4 shadow-sm"
                          style={{ borderTop: "none" }}
                          title={
                            <span>
                              <i className={item.PageIcon + " me-2"}></i>
                              {t(item.PageTitle)}
                            </span>
                          }
                        >
                          {item.MultiRecord ? (
                            <CRUDPageGRID
                              index={index}
                              active={selectedTab === `${index}`}
                              page={item}
                              ParentGUID={recordId}
                              actionMode={actionMode}

                            // updateForm={passingObject}
                            />
                          ) : (
                            <CRUDPageFORM
                              index={index}
                              active={selectedTab === `${index}`}
                              page={item}
                              ParentGUID={recordId}
                              actionMode={actionMode}
                              selectedItem={selectedItem}
                              doSubmit={doSubmit}
                              requestObject={requestObject}
                              setRequestObject={setRequestObject}
                              updateField={updateField}
                              parentModel={formik.values}
                              setRequiresPageObject={setRequiresPageObject}
                            // updateForm={passingObject}
                            />
                          )}
                        </Tab>
                      );
                    })}
                </Tabs>
              </Container>
              {showAudit && loadFormFieldsAudit(fields)}
            </Row>
          </div>
          <div className="card-footer">
            {generalError ? (
              <Row data-html2canvas-ignore="true">
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {t(generalError)}
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row data-html2canvas-ignore="true">
              <Col id="submit-container" xs={12} className="text-end ">
                {Object.values(formik.errors).length > 0 || childErrors ? (
                  <span className="invalid me-2">
                    {t("Please check the forms for errors")}
                  </span>
                ) : null}
                <button
                  className="btn btn-primary me-2"
                  type="button"
                  id="submit-button"
                  onClick={() => {
                    if (requiresPageObject) {
                      //We need to stop and request the objects of the page
                      setRequestObject(!requestObject);
                    }
                    formik.submitForm();
                  }}
                  data-html2canvas-ignore="true"
                >
                  {t("Save")}
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => toggleOpen(null)}
                  data-html2canvas-ignore="true"
                >
                  {t("Close")}
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  };

  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>{t("Filters")}</Card.Title>

              <button
                className="btn btn-secondary"
                title="Close"
                type="button"
                onClick={() => {
                  setIsSearching(false);
                }}
                style={{
                  padding: "0px 8px",
                  lineHeight: "23px",
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="formBackground p-0">
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder={t("Search by description")}
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  title={t("Clear")}
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  title={t("Search")}
                  type="submit"
                >
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )}
        <Card>
          <Card.Body className="p-0">
            <DataGrid
              dataSource={model.data.Rows}
              columns={model.data.Columns}
              columnAutoWidth={true}
              showBorders={true}
              onSelectionChanged={selectItem}
              loadPanel={{
                enabled: true,
                showIndicator: true,
                text: "Loading...",
              }}
            >
              <Selection mode="single" />
              <Paging defaultPageSize={8} />
              <Pager
                showInfo={true}
                infoText={"Page:"}
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </>
    );
  };

  const checkIfPinned = async () => {
    try {
      await db.favorites
        .where("username")
        .equals(user.Username)
        .toArray((item) => {
          if (item.length > 0) {
            const favExists = item[0].values.some(
              (fav) => fav.path === pathname
            );
            setIsPinned(favExists);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  //EVENTS
  const selectItem = useCallback((e) => {
    selectedItemRef.current = e.component;
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    if (childModel !== null) {
      formik.submitForm();
    }
  }, [childModel]);

  // useEffect(() => {
  //   if (allowSubmitWithPageContent === true) {
  //     formik.submitForm();

  //   }
  // }, [allowSubmitWithPageContent])

  useEffect(() => {
    setOpen(false);
    setSelectedItem(null);
    setActionMode(null);
    setGeneralError(null);
    setModel(initialModel);
    checkIfPinned();
    setIsSearching(false);
    setSearch("");
    bindDataRemote();
    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    console.log("section updated", model);
    prepareFormikObject();
  }, [model.section, model.section.Fields]);
  // END EVENTS
  if (!model) {
    return <></>;
  }

  return (
    <React.Fragment>
      <div className="row mx-0" id="contentContainer">
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2 titleContainer">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div className="col-auto"></div> */}
                  <div className="d-flex align-items-center my-1">
                    <i className={model.section.FormIcon + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{model.section.FormTitle}</h5>
                      <small>{model.section.FormPath}</small>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
                <button
                  type="button"
                  className={`btn btn-blue-overline${isPinned ? "-selected" : ""
                    } me-2`}
                  title="Pin to Favorites"
                  onClick={() => addFavorite()}
                  data-html2canvas-ignore="true"
                >
                  <i className="fa fa-thumbtack"></i>
                </button>

                {actionMode === null ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary my-1 me-2"
                      title="Create"
                      disabled={loading}
                      onClick={() => toggleOpen("I")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                    {/* {!isSearching && (
                      <button type="button" className={`btn btn-primary my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )} */}
                  </>
                ) : null}
                {!open && selectedItem ? (
                  <>
                    <button
                      className="btn btn-primary my-1 me-2"
                      title="Update"
                      disabled={loading}
                      onClick={() => toggleOpen("U")}
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button
                      className="btn btn-primary my-1 me-2"
                      title="View"
                      disabled={loading}
                      onClick={() => toggleOpen("C")}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary my-1 me-2"
                      title="Duplicate"
                      disabled={loading}
                      onClick={() => toggleOpen("D")}
                    >
                      <i className="fa-solid fa-copy"></i>
                    </button>
                    <button
                      id="delete-button"
                      className="btn btn-secondary my-1 me-2"
                      type="button"
                      disabled={loading}
                      title="Delete"
                      onClick={() => deleteConfirm()}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </>
                ) : null}
                {actionMode === "C" && (
                  <button
                    type="button"
                    className="btn btn-blue-overline my-1 me-2"
                    title="Export to PDF"
                    disabled={loading}
                    onClick={() => exportToPDF()}
                    data-html2canvas-ignore="true"
                  >
                    <i className="fa-solid fa-file-pdf"></i>
                  </button>
                )}
                {actionMode === null && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    type="button"
                    disabled={loading}
                    title="Export to Excel"
                    onClick={() => exportToExcel()}
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </button>
                )}
                {/* <div className="ms-3">
                  <button
                    className="btn border border-dark my-1 me-2"
                    type="button"
                    title="Close Window"
                    onClick={() => { navigate("/dashboard") }}
                    data-html2canvas-ignore="true"
                  >
                    <i className="fa-solid fa-x text-dark"></i>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          {/* {loadFormUI(model.Fields)} */}
          {open ? loadFormUI() : loadGRIDUI()}
          {/* NOW WE NEED TO LOAD THE PAGES THE SECTION HAS */}

          {/* {open ? loadFormUI() : loadGRIDUI()} */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CRUDMaster;
