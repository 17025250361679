import "./tableStyle.css";
import { Card } from "react-bootstrap";
import { parsedUser } from "../../utils/GetCurrentUser";
import { reservationColor, roomColor } from "../../utils/custom-cell";
import { ReservationTableFields } from "./ReservationFields";
import { useTranslation } from "react-i18next";
import API from "../../api/api";
import CommentsList from "./CommentsList";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import React, { useEffect, useState } from "react";
import ReservationFilter from "./Reservationfilter";

const ReservationContainer = ({
  setSelectedItem,
  selectedItem,
  // showAddEdit,
  setReloadData,
  reloadData,
}) => {
  const { t } = useTranslation();

  // PROPERTIES
  const user = parsedUser();
  const [recordCount, setRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };
  const [showComments, setShowComments] = useState(false);
  const [model, setModel] = useState(initialModel);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [sortBy, setSortBy] = useState("ReservationNumber");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [tableHeader] = useState(ReservationTableFields);
  const [objSPVersion, setObjSPVersion] = useState(`@FSInhouse=true, @FSReserved=true, @IdBusinessUnit=${user.IdBusinessUnit}, @FSAll=false`); //This object will be used to filter the data
  // END PROPERTIES

  // METHODS

  const toggleComments = () => {
    setShowComments(!showComments);
  };
  const bindDataRemote = async (filter) => {
    // First start loading the model
    setIsLoading(true);

    // if (filter === undefined || filter === null || filter === "") {
    //   filter = `@IdBusinessUnit=${user.IdBusinessUnit}`;
    // }
    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;
      let prefix = "Reservations";
      let queryData = {
        Data: filter,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/${prefix}/ReservationRecords?` + query,
        queryData ?? ""
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData || [];
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];

      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });
      // console.log("model", model)
      await bindDataCountRemote(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataCountRemote = async (filter) => {
    // First start loading the model
    // setLoading(true);

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(
        `api/Reservations/ReservationCount?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    setCurrentPage(1);
    await bindDataRemote(obj);
  };

  useEffect(() => {
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
  }, [sortBy, sortByOrder, RecordsQuantity, currentPage]);

  useEffect(() => {
    if (reloadData) {
      bindDataRemote(objSPVersion);
      setReloadData(false);
    }
  }, [reloadData]);

  return (
    <>
      {showComments && (
        <CommentsList
          toggle={toggleComments}
          show={true}
          selectedItem={selectedItem}
        />
      )}
      {/* BUTTON BAR */}
      {/* FILTER */}
      <ReservationFilter
        count={recordCount}
        handleFilter={handleFilter}
        isLoading={isLoading}
      />
      {/* CONTAINER */}
      <Card>
        <Card.Body>
          <div className="w-100">
            <div className="wrapper" style={{ overflowY: "auto" }}>
              <table className="table table-bordered shadow-sm table-striped">
                <thead>
                  <tr>
                    {headerWithoutAuditFields().map((item, index) => {
                      return (
                        <th
                          key={index}
                          className={`fw-bold clickable`}
                          onClick={() => {
                            setSortBy(item.FieldName);
                            if (item.FieldName === sortBy) {
                              if (sortByOrder === "ASC") {
                                setSortByOrder("DESC");
                              } else {
                                setSortByOrder("ASC");
                              }
                            } else {
                              setSortByOrder("ASC");
                            }
                          }}
                        >
                          {t(item.FieldTitle)}{" "}
                          {sortBy === item.FieldName &&
                            sortByOrder === "ASC" ? (
                            <i className="fas fa-sort-up"></i>
                          ) : sortBy === item.FieldName &&
                            sortByOrder === "DESC" ? (
                            <i className="fas fa-sort-down"></i>
                          ) : null}{" "}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {model?.data &&
                    model?.data?.Rows.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={` clickable ${item === selectedItem ? " active" : ""
                            }`}
                          onClick={() => {
                            setSelectedItem(item);
                          }}
                        >
                          <td className="sticky-col first-col">
                            <span
                              className="badge w-100"
                              style={roomColor(item)}
                            >
                              {item.Room}
                            </span>
                          </td>
                          <td className="sticky-col second-col">
                            {item.RoomType}
                          </td>
                          <td className="text-nowrap">{item.memberName}</td>
                          <td className="text-nowrap">{item.GuestName}</td>
                          <td className="text-nowrap">{item.Membership}</td>
                          <td className="text-nowrap">{item.memberCat}</td>
                          <td className="text-nowrap">
                            {item.ReservationNumber}
                          </td>

                          <td className="text-nowrap">
                            {item.ArrivalDate
                              ? moment(item.ArrivalDate).format("MM/DD/YYYY")
                              : null}
                          </td>
                          <td className="text-nowrap">
                            {item.DepartureDate
                              ? moment(item.DepartureDate).format("MM/DD/YYYY")
                              : null}
                          </td>
                          <td className="text-nowrap">{item.MealPlan}</td>
                          <td className=" text-nowrap">
                            <span
                              className="badge w-100"
                              style={reservationColor(item)}
                            >
                              {item.ReservationDescription}
                            </span>
                          </td>
                          <td className="text-nowrap">{item.Customer}</td>
                          <td className="text-nowrap text-center p-0">
                            {item.HasComments > 0 ? (
                              <button
                                className="btn btn-sm w-100 py-2"
                                type="button"
                                onClick={() => toggleComments()}
                              >
                                <i className="fa-solid fa-eye fs-5"></i>
                              </button>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="text-nowrap">{item.BusinessUnit}</td>
                          <td className="text-nowrap text-center">
                            {item.BillingBusinessUnit || "-"}
                          </td>
                          <td className="text-nowrap">{item.BraceletType}</td>
                          <td className="text-nowrap">{item.BraceletID}</td>
                          <td className="text-nowrap">{item.GroupName}</td>
                          <td className="text-nowrap">
                            {item.ReservationGroupNumber}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <Pagination
                isLoading={isLoading}
                RecordsQuantity={RecordsQuantity}
                recordCount={recordCount}
                // totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsQuantity={setRecordsQuantity}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReservationContainer;
