export const TaskAssignmentTableFields = [
  {
    FieldName: "AutomaticTask",    
    FieldTitle: "Automatic Task",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "TaskDate",
    FieldTitle: "Task Date",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "BusinessUnit",
    FieldTitle: "Business Unit",
    FieldType: "varchar",
    HtmlType: "text",
  },
  {
    FieldName: "TotalAttendants",
    FieldTitle: "Total Attendants",
    FieldType: "varchar",
    HtmlType: "text",
  },
]
