import { useFormik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useState
} from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as yup from "yup";
import API from "../../../../api/api";
import { useToast } from "../../../../contexts/toast";
import { parsedUser } from "../../../../utils/GetCurrentUser";
import yupTypeValidator from "../../../../utils/YupTypeValidator";
import FieldSelector from "../../../customComponents/FieldSelector";
import CHARGESDISCOUNTFIELDS from "../../Fields/RatePeriodChargesDiscountsFields";

import { useTranslation } from "react-i18next";

const SupplementsGeneral = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [validationsSchema, setValidationSchema] = useState(null);
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };
  const user = parsedUser();
  const toast = useToast();

  const selectedItem = props.selectedItem;
  const actionMode = props.actionMode;

  //This method is to save the record we are working on
  const handleSubmit = async (values, event) => {
    console.log("❗ ~ handleSubmit ~ obj:", values);

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = values;
      values = rest;
    }

    try {
      let objSPVersion = `@GUIDContract='${props.contractGUID}'`;
      fields.forEach((item) => {
        if (item.HtmlType === "separator") return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            values[item.FieldName] === undefined ||
            values[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue > 0
                ? item.DefaultValue
                : "null"
              : "'" + values[item.FieldName] + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            values[item.FieldName] === undefined ||
            values[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + values[item.FieldName] + "'";
        } else {
          objSPVersion +=
            values[item.FieldName] === undefined ||
            values[item.FieldName] === null
              ? "null"
              : "'" + values[item.FieldName] + "'";
        }
      });

      if (props.actionMode === "U") {
        console.log("selected item", selectedItem);
        //	objSPVersion += `, @GUID='${selectedItem.GUID}'`
      }
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

      let queryData = {
        Data: objSPVersion,
      };

      let request = await API.postAction(
        "api/contracts/SupplementsGeneralAddEdit?" + queryString,
        queryData
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          //	setGeneralError(response.Msg);
          return;
        }
        console.log(response.JSONData);
        debugger;
        props.setSelectedITem(response.JSONData);
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      props.setStep(props.step + 1);
      props.setActionMode("U");
      //	toggleOpen(null, true);
    } catch (error) {
      console.error(error);
    } finally {
      props.setRequestSubmitGeneral(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: (obj) => {
      handleSubmit(obj);
    },
  });

  const fillFormikObject = (record) => {
    console.log(record);
    if (record && fields && fields.length > 0) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };
  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const [fields, setFields] = useState(CHARGESDISCOUNTFIELDS);
  useEffect(() => {
    prepareFormikObject();
    fillFormikObject(props.selectedItem);
  }, [props.selectedItem]);
  useEffect(() => {
    if (props.requestSubmitGeneral) {
      formik.submitForm();
    }
  }, [props.requestSubmitGeneral]);

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            {fields.map((item, index) => (
              <Col
                className={item.cssClasss + (!item.Visible ? " d-none" : "")}
                key={index}
              >
                <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                {item.Required ? (
                  <i className="required-asterisk ms-1 text-danger">*</i>
                ) : null}
                <br />

                <FieldSelector
                  section="SupplementsGeneral"
                  sectionReferenceContent={props.Contract}
                  actionMode={props.actionMode}
                  model={item}
                  key={index}
                  obj={formik.values}
                  updateField={updateField}
                  value={formik.values[item.FieldName]}
                ></FieldSelector>
                {formik.errors[item.FieldName] ? (
                  <div className="invalid text-sm">
                    {formik.errors[item.FieldName]}
                  </div>
                ) : null}
              </Col>
            ))}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
});

export default SupplementsGeneral;
