import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FieldSelector from "../../customComponents/FieldSelector";
import { HeaderInfoFields } from "../fields/HeaderInfoFields";

import { useTranslation } from "react-i18next";

const ReservationHeadersDetailsInfo = ({ selectedReservation }) => {
  const { t } = useTranslation();

  const [fields] = useState(HeaderInfoFields);
  const [record, setRecord] = useState(null);

  const updateField = (fieldName, fieldValue) => {};

  useEffect(() => {
    // console.log(selectedReservation);
    /* if (selectedReservation) {
      setRecord(selectedReservation);
    } */
    setRecord(selectedReservation);
  }, [selectedReservation]);

  if (!record) {
    return <></>;
  }
  return (
    <Row>
      {fields.map((item, index) => {
        // console.log(item, record);
        return (
          <Col
            className={item.cssClasss + (!item.Visible ? " d-none" : "")}
            key={index}
          >
            <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
            {item.Required ? (
              <i className="required-asterisk ms-1 text-danger">*</i>
            ) : null}
            <br />

            <FieldSelector
              actionMode={"I"}
              model={item}
              key={index}
              obj={record}
              updateField={updateField}
              value={record[item.FieldName]}
              IsReadOnly
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default ReservationHeadersDetailsInfo;
