
// Toast Types: success, error, warning, info

import { Toast } from "devextreme-react";
import { createContext, useCallback, useContext, useMemo, useState } from "react"

const ToastContext = createContext({
    show: ({ type, message, duration = 3500, width = 50, height = 20 }) => { }
});

const initialState = {
    isVisible: false,
    type: "info",
    message: "",
    duration: 3500,
    width: 50,
    height: 20
};

function ToastProvider({ children }) {
    const [toastConfig, setToastConfig] = useState(initialState)

    const show = useCallback(({ type, message, duration = 3500, width = 75, height = 20 }) => {
        setToastConfig({
            isVisible: true,
            duration,
            type,
            message,
            width,
            height
        })
    }, [])

    const toastConfigValues = useMemo(() => toastConfig, [toastConfig]);

    const hide = useCallback(() => setToastConfig(initialState), [])

    const contentRender = () => {
        return (
            <div className="w-100 d-flex gap-2 ">
                <div className="row align-items-center">
                    <div className="col-auto fs-3">
                        <i className="fa-solid fa-circle-info"></i>
                    </div>
                    <div className="col-auto">
                        <div className="mx-auto fw-bold fs-6">{toastConfigValues.message}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <ToastContext.Provider value={show}>
            {children}
            <Toast
                visible={toastConfigValues.isVisible}
                type={toastConfigValues.type}
                contentRender={contentRender}
                duration={toastConfigValues.duration}
                onHiding={hide}
                closeOnSwipe={true}
                width={toastConfigValues.width || "auto"}
                height={toastConfigValues.height}
                position={{ at: "top center", my: "top center", offset: { x: "5px", y: "5px" } }}
            />
        </ToastContext.Provider>
    )
}

const useToast = () => useContext(ToastContext);
export { ToastProvider, useToast }