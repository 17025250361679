import { Col, Container, Row } from "react-bootstrap";
import { GenericFields } from "./GenericFields";
import { useEffect, useState } from "react";
import { RateTabFields } from "./FieldsGroup/RateTabFields";
import moment from "moment";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

import { useTranslation } from "react-i18next";

export const RateTab = ({ errors, touched, updateFields, values }) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const [fields, setFields] = useState(RateTabFields);
  const [rateCategoryTypes, setRateCategoryTypes] = useState([]);
  const [selectedRateCategoryType, setSelectedRateCategoryType] =
    useState(null);

  useEffect(() => {
    requestRateCategoryTypes();
  }, []);

  const requestRateCategoryTypes = async (id) => {
    try {
      let query = `token=${user.Token}&id=97cf9a0606f82ed6e8885961e5fbce5cdd82909e2343e3f5b2e38fc31807ae95`;
      var request = await API.getAction("api/selector", query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        console.error(parsedResults.Msg);
        return;
      }
      let recordResult = JSON.parse(parsedResults.JSONData);
      if (recordResult) {
        setRateCategoryTypes(recordResult);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateField = async (fieldName, fieldValue, optionText, dontUpdate) => {
    console.log(fieldName, fieldValue);
    if (fieldName === "BookingNumber") {
      debugger;
    }
    if (fieldValue === "Invalid date") {
      fieldValue = null;
    }

    if (fieldName === "IdRateCategoryType") {
      // console.log(rateCategoryTypes.filter(x => x.Id === fieldValue)[0]?.Code);
      setSelectedRateCategoryType(
        rateCategoryTypes.filter((x) => x.Id === fieldValue)[0]
      );
    }

    // check if arrivalDate is greater than departure date
    if (fieldName === "ArrivalDate" && values.DepartureDate && !dontUpdate) {
      if (moment(fieldValue).isAfter(values.DepartureDate)) {
        updateFields("DepartureDate", fieldValue);
      }
    }

    // Check if arrivalDate change and nights is set should update the departure date by adding nights.
    if (fieldName === "ArrivalDate" && values.Nights && !dontUpdate) {
      let addDays = moment(fieldValue).add(values.Nights, "days");
      values.DepartureDate = addDays;
      updateFields("DepartureDate", addDays);
    }

    // Check if nights have change
    if (fieldName === "Nights" && values.ArrivalDate && !dontUpdate) {
      let addDays = moment(values.ArrivalDate).add(fieldValue, "days");
      values.DepartureDate = addDays;
      updateFields("DepartureDate", addDays);
    } else if (fieldName === "DepartureDate" && !dontUpdate) {
      let days = moment(fieldValue).diff(moment(values.ArrivalDate), "days");
      values.Nights = isNaN(days) ? 0 : days;
      updateFields("Nights", values.Nights);
    }

    values[fieldName] = fieldValue;
    updateFields(fieldName, fieldValue);
  };

  // useEffect(() => {
  //   setSelectedRateCategoryType(rateCategoryTypes.filter((x) => x.Id === values?.IdRateCategoryType)[0]);
  // },[selectedRateCategoryType])

  useEffect(() => {
    if (selectedRateCategoryType) {
      let fieldsObj = fields;
      //formik.setFieldValue("IdRateCategoryType", selectedRateCategoryType.Id);

      switch (selectedRateCategoryType.Code) {
        case "P": //Complementary
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              // updateField("TotalStay", 0);
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "C": //Contract
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "H": // House Use
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              // updateField("TotalStay", 0);
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "M": // Rate assigned
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = true;
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        default:
          break;
      }
      // console.log("change", selectedRateCategoryType, fieldsObj);
    } else {
      setSelectedRateCategoryType(
        rateCategoryTypes.filter((x) => x.Id === values?.IdRateCategoryType)[0]
      );
    }
  }, [selectedRateCategoryType]);

  return (
    <Container fluid>
      <Row className="py-0">
        <Col xs={12} md={6}>
          <Row>
            <GenericFields
              fields={fields.filter((x) => x.GroupBy === 0)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <GenericFields
              fields={fields.filter((x) => x.GroupBy === 1)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
