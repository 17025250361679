import Sha256 from "./sha256";

export const getFilterCriteria = (obj, filters) => {
  const filterCriteria = filters.filter(item => item.ReportCriteria).map(item => {
    const value = obj[item.FieldName];
    return {
      name: item.FieldTitle,
      value: value === null || value === undefined ? "All" : `${value}`,
      fieldType: item.HtmlType
    }
  })
  return filterCriteria;
}

/**
 * Processes the given object and filters to generate a string representation.
 *
 * @param {Object} obj - The object containing the data to be filtered.
 * @param {Array} filters - An array of filter objects that define how to process the data.
 * @param {string} filters[].FieldName - The name of the field in the object.
 * @param {string} filters[].HtmlType - The type of HTML input (e.g., "date", "password", "radio", "separator").
 * @param {string} filters[].FieldType - The type of the field (e.g., "int", "bool", "bit", "bigint").
 * @param {boolean} filters[].ReadOnly - Indicates if the field is read-only.
 * @param {string} [filters[].DefaultValue] - The default value to use if the field value is undefined or null.
 * @returns {string} A string representation of the filtered object.
 */
export const handleFilter = (obj, filters) => {
  let objSPVersion = "";
  filters.forEach((item) => {

    if (item.ReadOnly || item.HtmlType === "separator") return;

    if (item.HtmlType === "date") {
      if (obj[item.FieldName] === 'Invalid date') {
        obj[item.FieldName] = null;
      }
    }

    if (item.HtmlType !== "separator") {
      objSPVersion +=
        (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
    }
    if (
      item.FieldType === "int" ||
      item.FieldType === "bool" ||
      item.FieldType === "bit" ||
      item.FieldType === "bigint"
    ) {
      objSPVersion +=
        obj[item.FieldName] === undefined || obj[item.FieldName] === null || obj[item.FieldName] === "null" || obj[item.FieldName] === ""
          ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
          : obj[item.FieldName] ?? "null";

    } else if (item.HtmlType === "password") {
      let passwordEncrypted = null;
      if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
        passwordEncrypted =
          obj["UserName"].toUpperCase() + obj[item.FieldName];
        passwordEncrypted = Sha256.hash(passwordEncrypted);
        ;
      }
      objSPVersion +=
        obj[item.FieldName] === undefined
          ? "null"
          : "'" + passwordEncrypted + "'"
    } else if (item.HtmlType === 'radio') {
      objSPVersion +=
        obj[item.FieldName] === undefined || obj[item.FieldName] === null
          ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
          : "'" + obj[item.FieldName] + "'";
    } else {
      objSPVersion +=
        obj[item.FieldName] === undefined || obj[item.FieldName] === null || obj[item.FieldName] === "null" || obj[item.FieldName] === ""
          ? "null"
          : "'" + obj[item.FieldName] + "'";
    }
  });

  return objSPVersion;

}