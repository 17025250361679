import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { GenericFields } from "../group-reservation/GenericFields";
import { handleFilter as filterUtil } from '../../utils/filter-utils';
import { RoomFilterFields } from "./RoomsFields";
import { Search } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import yupTypeValidator from '../../utils/YupTypeValidator';

const RoomsFilter = ({ handleFilter, count, isLoading }) => {
  const fields = RoomFilterFields;
  const [validationsSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
  }, [])

  const handleSubmit = (formikObj) => {
    const obj = filterUtil(formikObj, fields);
    handleFilter(obj);
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));

  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  return (
    <Accordion className="mb-4 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0"><span className='h5 m-0' style={{ fontSize: '14px' }}>Filter</span></Accordion.Header>
        <Accordion.Body className="bg-light p-0">
          <Card className="w-100">
            <Form onSubmit={formik.handleSubmit}>
              <Card.Body className="bg-light">
                <Row className='mx-0'>
                  <GenericFields
                    errors={formik.errors}
                    values={formik.values}
                    updateFields={updateField}
                    fields={fields}
                  />
                </Row>
              </Card.Body>
              <Card.Footer >
                <Row>
                  <Col>
                    <div className='display-6 text-success'>
                      {/* Records found: {count || 0} */}
                    </div>
                  </Col>
                  <Col xs='auto text-end'>
                    <button
                      className="btn btn-sm btn-secondary me-2"
                      type="button"
                      title="Clear"
                      onClick={() => formik.resetForm()}
                    ><i className="fas fa-eraser"></i></button>
                    <button className="btn btn-sm btn-primary" title="Search" type="submit" disabled={isLoading}><Search /></button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default RoomsFilter
