import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";
import CustomBreadcrumbs from "../../breadcrumb";
import RoomOverviewFilter from "./RoomOverviewFilter";
import { useTranslation } from "react-i18next";
import RoomOverviewCardList from "../Component/RoomOverviewCardList";
import RoomOverviewTableDetails from "../Component/RoomOverviewTableDetails";

const RoomOverviewContainer = ({ routeName }) => {
  const { t } = useTranslation();
  const user = parsedUser();

  const [isLoading, setIsLoading] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [tempFilter, setTempFilter] = useState(null);
  // const [totalPages, setTotalPages] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [RecordsQuantity, setRecordsQuantity] = useState(10);

  // const [showTasksPlanning, setShowTasksPlanning] = useState(false);

  const requestRecords = async (filter) => {
    setRecords([]);
    setRecordCount(0);
    if (filter === null || filter === undefined || filter === "") {
      filter = `@IdBusinessUnit=${user.IdBusinessUnit}`;
    }
    setIsLoading(true);
    try {
      const queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${"L"}`;
      const queryData = {
        Data: filter,
      };

      const requestAPI = await API.postAction("/api/AutomaticTasksAssigments/HousekeepingRoomCleaning?" + queryString, queryData);
      const newData = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0).Records;
      // console.log(newData);

      if (newData && newData.length > 0) {
        setRecords(newData);
        setRecordCount(newData.length);
        return;
      }
      setRecords([]);
      setRecordCount(0);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = (values) => {
    requestRecords(values);
    setTempFilter(values);
  }

  useEffect(() => {
    requestRecords(null);
  }, []);

  return (
    <>
      <CustomBreadcrumbs pathTitle={routeName} />

      <Container className="mt-0 pt-0">
        <div className="row mx-3">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="dx-icon fas fa-broom me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{t("Room Overview")}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <RoomOverviewFilter
            filterRecord={handleFilter}
            count={recordCount}
            isLoading={isLoading}
          />

          <Card>
            <Card.Body>
              <Row>
                <Col xs={6}>
                  <h6>{t("Rooms Details")}</h6>
                </Col>
                <Col xs={6} className="text-end">
                </Col>
                <Col xs={12}>
                  <Tabs defaultActiveKey={"listDetails"}>
                    <Tab eventKey={"listDetails"} title={t("Building View")}>
                      <RoomOverviewCardList
                        records={records}
                        isLoading={isLoading}
                        reload={() => requestRecords(tempFilter)}
                      />
                    </Tab>
                    <Tab eventKey={"tableDetails"} title={t("Room Status Details")}>
                      <RoomOverviewTableDetails
                        records={records}
                        isLoading={isLoading}
                        reload={() => requestRecords(tempFilter)}
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default RoomOverviewContainer;
