import { useFormik } from "formik";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import * as yup from "yup";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import CRUDPageGRIDCustom from "../../pages/crud/CRUDPageGridCustom";
import { parsedUser } from "../../utils/GetCurrentUser";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { dateFormater } from "../../utils/date-formater";
import Pagination from "../Pagination/Pagination";
import CancelReservation from "../reservations/CancelReservation";
import RegistrationCardModal from "../reservations/RegistrationCardModal";
import ReservationFormModal from "../reservations/ReservationFormModal";
import UnCancelReservation from "../reservations/UnCancelReservation";
import AdvancedRoomSelection from "../reservations/reservationDetails/AdvancedRoomSelection";
import ReservationDetails from "../reservations/reservationDetails/ReservationDetails";
import { GroupTabFields } from "./FieldsGroup/GroupTabFields";
import { HeaderFields } from "./FieldsGroup/HeaderFields";
import { RateTabFields } from "./FieldsGroup/RateTabFields";
import { GroupReservationHeaderDetails } from "./GroupReservationHeaderDetails";
import { GroupTab } from "./GroupTab";
import { RateTab } from "./RateTab";
import TableRoomsTypes from "./TableRoomTypes";
import UndoCheckIn from "./UndoCheckIn";

import { useTranslation } from "react-i18next";

const GuestReservationList = ({ data, requestReservationTable, listCount }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  let sumTotal = 0;
  // let totalRooms = 0;
  // let totalAdults = 0;
  // let totalChildren = 0;
  const [actionMode, setActionMode] = useState("U");
  const [showReservationDetails, setShowReservationDetails] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const toast = useToast();
  const user = parsedUser();
  const [showReservationForm, setShowReservationForm] = useState(false);
  const [showRegistrationCard, setShowRegistrationCard] = useState(false);
  const [showCancelReservation, setShowCancelReservation] = useState(false);
  const [showUnCancelReservation, setShowUnCancelReservation] = useState(false);
  const [showAdvancedRoom, setShowAdvancedRoom] = useState(false);
  const [showUndoCheckIn, setShowUndoCheckIn] = useState(false);

  // useEffect(() => {
  //   console.log({ total: "totals", totalRooms, totalAdults, totalChildren })
  //   listCount({ Rooms: totalRooms, Adults: totalAdults, Children: totalChildren });
  // }, [totalRooms, totalAdults, totalChildren]);

  // BUTTONS
  const principalButtonList = [
    {
      id: 1,
      title: t("Edit"),
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "B"],
      onClick: () => {
        toggleReservationDetails("U");
      },
    },

    {
      id: 3,
      title: t("Cancel"),
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-times",
      ifSelectedItem: true,
      reservationCode: ["T", "C"],
      onClick: () => {
        toggleCancelReservation();
      },
    },
    {
      id: 4,
      title: t("Uncancel"),
      variant: "outline-danger",
      className: "",
      icon: "fa-solid fa-rotate-left",
      ifSelectedItem: true,
      reservationCode: ["X"],
      onClick: () => {
        toggleUnCancelReservation();
      },
    },
    {
      id: "checkInBtn",
      title: t("Check In"),
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: ["C", "N", "T", "P"],
      onClick: () => {
        toggleReservationDetails("K");
        console.log("PRESSED CHECKIN BUTTON");
      },
    },
    {
      id: "checkOutBtn",
      title: t("Check Out"),
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: ["I", "H"],
      onClick: () => {
        toggleReservationDetails("O");
        console.log("PRESSED CHECKOUT BUTTON");
      },
    },
    {
      id: 6,
      title: t("Undo Check-In"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-sign-out",
      ifSelectedItem: true,
      reservationCode: ["I"],
      onClick: () => {
        toggleUndoChekIn();
        console.log("PRESSED Undo Check-In Button");
      },
    },
    {
      id: "assignRoom",
      title: t("Assign Room"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-tent-arrow-down-to-line",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I"],
      onClick: () => {
        toggleShowAdvancedRoom();
        console.log("PRESSED Re Check-In Button");
      },
    },
    {
      id: 8,
      title: t("Re Check-In"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: [],
      onClick: () => {
        console.log("PRESSED Re Check-In Button");
      },
    },
    {
      id: 9,
      title: t("Bump Out"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-hotel",
      ifSelectedItem: true,
      reservationCode: ["C", "I"],
      onClick: () => {
        console.log("PRESSED Bump Out Button");
      },
    },

    {
      id: 10,
      title: t("Undo Checkout"),
      variant: "outline-secondary",
      className: "",
      icon: "",
      ifSelectedItem: true,
      reservationCode: ["O"],
      onClick: () => {
        console.log("PRESSED Undo Checkout Button");
      },
    },
  ];

  const secondeButtonList = [
    {
      id: 2,
      name: t("Res. Form"),
      onClick: () => toggleShowReservationForm(),
      reservationCode: ["T", "C", "I"],
      requireSelection: true,
    },
    {
      id: 3,
      name: t("Reg. Card"),
      onClick: () => toggleShowRegistrationCard(),
      reservationCode: ["T", "C", "I"],
      requireSelection: true,
    },
    {
      id: 4,
      name: t("Print Folios"),
      onClick: () => {},
      reservationCode: [],
      requireSelection: true,
    },

    {
      id: "releaseRoom",
      name: t("Release Room"),
      onClick: async () => {
        confirmAlert({
          message: t("Are you sure you want to release the room?"),
          buttons: [
            {
              label: t("Yes"),
              onClick: async () => {
                let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservation=${selectedItem.GUID}`;

                let prefix = "Reservations";
                await API.getAction(`api/${prefix}/ReleaseRoom`, query);
              },
            },
            { label: t("No") },
          ],
        });
      },
      reservationCode: ["C"],
      requireSelection: true,
    },
  ];

  const toggleUndoChekIn = () => {
    setShowUndoCheckIn(!showUndoCheckIn);

    if (showUndoCheckIn) {
      requestReservationTable();
    }
  };

  const toggleShowAdvancedRoom = () => {
    setShowAdvancedRoom(!showAdvancedRoom);
    if (showAdvancedRoom) {
      //MEans is going away

      requestReservationTable();
    }
  };
  const toggleCancelReservation = () => {
    setShowCancelReservation(!showCancelReservation);
    if (showCancelReservation) {
      requestReservationTable();
    }
  };

  const toggleUnCancelReservation = () => {
    setShowUnCancelReservation(!showUnCancelReservation);
    if (showUnCancelReservation) {
      requestReservationTable();
    }
  };

  const toggleShowRegistrationCard = () => {
    if (!selectedItem) {
      toast({
        type: "error",
        message: t("Please select a reservation."),
      });
      return;
    }
    setShowRegistrationCard(!showRegistrationCard);
  };
  const toggleShowReservationForm = () => {
    if (!selectedItem) {
      toast({
        type: "error",
        message: t("Please select a reservation."),
      });
      return;
    }
    setShowReservationForm(!showReservationForm);
  };

  const reservationColor = (item) => {
    return {
      // backgroundColor: item.ReservationStatusBackgroundColor,
      backgroundColor: item.ReservationStatusBackgroundColor || "gray",
      color: item.ReservationStatusTextColor,
    };
  };

  const roomColor = (item) => {
    return {
      // backgroundColor: item.ReservationStatusBackgroundColor,
      backgroundColor: item?.RoomStatusBackgroundColor || "gray",
      //borderLeft: "solid 5px " + item.RoomStatusBackgroundColor,
      color: item?.RoomStatusTextColor,
    };
  };

  const toggleReservationDetails = (action) => {
    setActionMode(action);

    setShowReservationDetails(!showReservationDetails);
  };
  return (
    <>
      {showUndoCheckIn ? (
        <UndoCheckIn
          show={showUndoCheckIn}
          toggle={toggleUndoChekIn}
          reservation={selectedItem}
        />
      ) : null}
      {showAdvancedRoom ? (
        <AdvancedRoomSelection
          show={showAdvancedRoom}
          selectedReservation={selectedItem}
          toggle={toggleShowAdvancedRoom}
        />
      ) : null}
      {showCancelReservation ? (
        <CancelReservation
          reservation={selectedItem}
          show={showCancelReservation}
          toggle={toggleCancelReservation}
        />
      ) : null}
      {showUnCancelReservation ? (
        <UnCancelReservation
          reservation={selectedItem}
          show={showUnCancelReservation}
          toggle={toggleUnCancelReservation}
        />
      ) : null}
      {showReservationDetails && (
        <ReservationDetails
          toggle={toggleReservationDetails}
          show={showReservationDetails}
          actionMode={actionMode}
          selectedReservation={{
            GUID: selectedItem.ReservationGUID,
          }}
          clearSelection={() => setSelectedItem(null)}
          handleClose={toggleReservationDetails}
        />
      )}
      {showRegistrationCard ? (
        <RegistrationCardModal
          show={showRegistrationCard}
          handleClose={() => setShowRegistrationCard(false)}
          GUIDReservation={selectedItem?.ReservationGUID}
        />
      ) : null}
      {showReservationForm ? (
        <ReservationFormModal
          show={showReservationForm}
          handleClose={() => setShowReservationForm(false)}
          GUIDReservation={selectedItem?.ReservationGUID}
        />
      ) : null}

      <Card>
        <Card.Header>
          <Card.Title>{t("Reservations")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className="mb-2 col-auto">
              {/* BUTTONS */}
              {principalButtonList.map((item) => {
                // console.log(selectedItem);
                if (item.ifSelectedItem && selectedItem) {
                  //Validate if checking is pressed
                  if (item.id === "checkInBtn" && !selectedItem.IdRoom) {
                    return <></>;
                  }
                  if (item.id === "assignRoom" && selectedItem.IdRoom) {
                    return <></>;
                  }

                  return item.reservationCode.length > 0 &&
                    item.reservationCode.includes(
                      selectedItem.ReservationStatusCode
                    ) ? (
                    <>
                      <Button
                        key={item}
                        variant={item.variant}
                        size="md"
                        className={item.className + " me-2"}
                        onClick={item.onClick}
                      >
                        <i className={item.icon + " me-2"} />
                        {item.title}
                      </Button>
                    </>
                  ) : (
                    <></>
                  );
                } else return <></>;
              })}
            </Col>
            <Col className="text-end mb-2">
              <div className="d-flex w-100 my-2 justify-content-end gap-1">
                <ButtonGroup size="md">
                  {secondeButtonList.map((item, index) => {
                    if (item.requireSelection) {
                      if (selectedItem) {
                        if (!selectedItem.IdRoom && item.id === "releaseRoom") {
                          return <></>;
                        }

                        return item.reservationCode.length > 0 &&
                          item.reservationCode.includes(
                            selectedItem.ReservationStatusCode
                          ) ? (
                          <>
                            <Button
                              key={item}
                              variant="outline-secondary"
                              size="md"
                              onClick={item.onClick}
                            >
                              {item.name}
                            </Button>
                          </>
                        ) : (
                          <></>
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <Fragment key={index}>
                          <Button
                            variant="outline-secondary"
                            size="md"
                            onClick={item.onClick}
                          >
                            {item.name}
                          </Button>
                        </Fragment>
                      );
                    }
                  })}
                </ButtonGroup>
              </div>
            </Col>
          </Row>
          {/* CONTENT */}
          <div className="table-responsive">
            <table className="table table-bordered shadow-sm table-striped">
              <thead>
                <tr>
                  <th className="text-nowrap">{t("Res#")}</th>
                  <th className="text-nowrap">{t("Guest Name")}</th>
                  <th className="text-nowrap">{t("Room")}</th>
                  <th className="text-nowrap">{t("Arrival")}</th>
                  <th className="text-nowrap">{t("Departure")}</th>
                  <th className="text-nowrap">{t("Total")}</th>
                  <th className="text-nowrap">{t("Adults")}</th>
                  <th className="text-nowrap">{t("Children 1")}</th>
                  <th className="text-nowrap">{t("Children 2")}</th>
                  <th className="text-nowrap">{t("Children 3")}</th>
                  <th className="text-nowrap">{t("Status")}</th>

                  <th className="text-nowrap">{t("Picked Up")}</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => {
                    sumTotal = sumTotal + item.TotalStay;
                    return (
                      <tr
                        key={index}
                        className={`clickable ${
                          item === selectedItem ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedItem(item);
                        }}
                      >
                        <td>{item.ReservationNumber}</td>
                        <td>{item.GuestName}</td>
                        <td>
                          <span className="badge w-100" style={roomColor(item)}>
                            {item.Room}
                          </span>
                        </td>
                        <td>
                          {item.ArrivalDate
                            ? moment(item.ArrivalDate).format("MM/DD/YYYY")
                            : null}
                        </td>
                        <td>
                          {" "}
                          {item.DepartureDate
                            ? moment(item.DepartureDate).format("MM/DD/YYYY")
                            : null}
                        </td>
                        <td className="text-end">{item.TotalStay}</td>
                        <td>{item.AdultQuantity}</td>
                        <td>{item.Child1Quantity}</td>
                        <td>{item.Child2Quantity}</td>
                        <td>{item.Child3Quantity}</td>
                        <td>
                          <span
                            className="badge w-100"
                            style={reservationColor(item)}
                          >
                            {item.ReservationDescription}
                          </span>
                        </td>

                        <td>{item.PickedUp}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="border-1 text-center" colSpan={11}>
                      {t("No Records Found")}
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={12} className="text-end">
                    <h4>
                      {t("Total:")} {sumTotal}
                    </h4>
                  </td>
                </tr>
              </tfoot>
            </table>
            <Pagination
              isLoading={isLoading}
              RecordsQuantity={RecordsQuantity}
              recordCount={recordCount}
              // totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setRecordsQuantity={setRecordsQuantity}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

const GroupReservationDetails = (props) => {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();
  const [count, setCount] = useState({
    Rooms: 0,
    Adults: 0,
    Children: 0,
  });
  const [formikInitialObject, setFormikInitialObject] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  // const [selectedGuestReservation, setSelectedGuestReservation] = useState(null);
  const [selectedGroupReservation, setSelectedGroupReservation] =
    useState(null);

  // const [selectedTab, setSelectedTab] = useState(null);
  const [actionMode, setActionMode] = useState(null);
  const allFields = [...GroupTabFields, ...RateTabFields, ...HeaderFields];

  const saveInitialGroupReservation = async () => {
    console.log("Saving initial reservation");
    console.log(selectedGroupReservation);
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=I`;
    let queryData = {
      Data: "@IdBusinessUnit=" + user.IdBusinessUnit,
    };
    let request = await API.postAction(
      `api/GroupReservations/ReservationAddEdit?${queryString}`,
      queryData
    );
    console.log("🚀 ~ saveInitialGroupReservation ~ request:", request);
    if (request.status === 200) {
      let response = JSON.parse(request?.data[0].JSONData);
      if (response?.Error) {
        alert(response.Msg);
        return;
      }
      setSelectedGroupReservation(response[0].JSONData);
      setSelectedGroupReservation((prev) => {
        return {
          ...prev,
          BookingDate: dateFormater(new Date()),
          IdRateCategoryType: 1,
        };
      });
      console.log(
        "🚀 ~ saveInitialGroupReservation ~ response[0].JSONData:",
        response[0].JSONData
      );

      formik.setFieldValue("GUID", response[0].JSONData.GUID);
      formik.setFieldValue("Id", response[0].JSONData.Id);

      // console.log("response,", response[0].JSONData)
      setActionMode("U");
    }
    // console.log(request)
  };
  const [reservations, setReservations] = useState([]);
  const requestReservationTable = async () => {
    console.log("Bind Data table");
    try {
      if (!selectedGroupReservation) {
        return;
      }
      let query = `IdUser=${user.IdUser}&token=${user.Token}&ParentGUID=${selectedGroupReservation?.GUID}`;

      let request = await API.postAction(
        "api/GroupReservations/ReservationsGroupsDetailsRecords?" + query,
        null
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      // console.table(recordResult);

      if (recordResult === null) {
        throw new Error(t("No data found"));
      }

      // Sum of the AdultQuantity, Child1Quantity, Child2Quantity, Child3Quantity into a single object.
      let totalAdults = 0;
      let totalChildren1 = 0;
      let totalChildren2 = 0;
      let totalChildren3 = 0;
      let totalRooms = 0;

      recordResult.forEach((item) => {
        totalAdults += item?.AdultQuantity;
        totalChildren1 += item?.Child1Quantity;
        totalChildren2 += item?.Child2Quantity;
        totalChildren3 += item?.Child3Quantity;
        totalRooms = recordResult.length;
      });

      setCount({
        Rooms: totalRooms,
        Adults: totalAdults,
        Children: totalChildren1 + totalChildren2 + totalChildren3,
      });

      setReservations(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const bindDataDetailsRemote = async () => {
    console.log("Bind Data Detail Remote");
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${props.selectedGroup?.GUID}`;
      let request = await API.getAction(
        "api/GroupReservations/ReservationDetails",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;

      if (recordResult === null) {
        throw new Error(t("No data found"));
      }
      console.log(recordResult);

      setSelectedGroupReservation(recordResult);
      fillFormikObject(recordResult);
      requestReservationTable();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const fillFormikObject = (record) => {
    formik.setValues(record);

    //Now
  };

  // Events
  useEffect(() => {
    if (props.actionMode && props.actionMode === "I") {
      // setSelectedGuest(null);
      //Insert minimal information
      saveInitialGroupReservation();
    }
  }, [props.actionMode]);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  useEffect(() => {
    if (selectedGroupReservation) {
      fillFormikObject(selectedGroupReservation);
      requestReservationTable();
    }
  }, [selectedGroupReservation]);

  useEffect(() => {
    if (props.selectedGroup && props.actionMode === "U") {
      bindDataDetailsRemote();
    }
    console.log(props.selectedGroup);
  }, [props.selectedGroup]);

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (allFields) {
      allFields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject({ ...initialObject, IdRateCategoryType: "C" });
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const handleSave = async (obj) => {
    //* Replace the apostrophes and/or double quotes from the object values to avoid SQL errors.
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].replace(/'/g, "''");
      }
    });

    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].replace(/"/g, '""');
      }
    });

    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      toast({
        type: "error",
        message: t("Missing fields"),
      });
      console.error(errors);
      return;
    }

    let actionMode = props.actionMode; // this is a workaround
    if (actionMode === "I") {
      actionMode = "U";
    }

    let objSPVersion = "";

    Object.keys(selectedGroupReservation).forEach((item) => {
      // console.log(
      //   "field",
      //   item,
      //   selectedGroupReservation["Id" + item],
      //   selectedGroupReservation[item],
      //   typeof selectedGroupReservation[item]
      // );
      if (
        item === "RowStatus" ||
        item === "IdUserAdd" ||
        item === "DateAdd" ||
        item === "IdUserMod" ||
        item === "DateMod" ||
        item === "Id" ||
        (item && selectedGroupReservation["Id" + item]) ||
        item === "Customer"
      ) {
        return;
      }
      objSPVersion += (objSPVersion.length > 0 ? ", " : "") + `@${item}=`;
      if (obj[item] === null || obj[item] === undefined) {
        if (item === "GUID") {
          objSPVersion += `'${selectedGroupReservation.GUID}'`;
        } else {
          objSPVersion += "null";
        }
      } else if (typeof obj[item] === "number") {
        objSPVersion +=
          obj[item] === undefined || obj[item] === null ? null : obj[item];
      } else if (typeof [obj[item] === "string"]) {
        objSPVersion += "'" + obj[item] + "'";
      } else {
        objSPVersion += obj[item];
        //   "null";
      }
    });

    //objSPVersion += ", @IdReservationGroup=" + selectedGroupReservation.Id

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

    let queryData = {
      Data: objSPVersion,
    };

    let uri = "api/GroupReservations/ReservationAddEdit?";

    let request = await API.postAction(uri + queryString, queryData);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        return;
      }
    }
    toast({
      type: "success",
      message: t("Record saved successfully"),
    });
    props.handleClose();
    // props.toggle(null, true)
  };

  const handleCloseGroupReservation = () => {
    // console.log('Closing Group Reservation Details')
    props.handleClose();
    props.clearSelection();
  };

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  const [selectedRoomsType, setSelectedRoomsType] = useState(null);
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Modal
          show={props.show}
          onHide={handleCloseGroupReservation}
          backdrop="static"
          keyboard={false}
          className="modalRight"
          dialogClassName="modal-70w"
          size="xl"
        >
          <Modal.Header closeButton>
            <Row>
              <Col>
                <h4>
                  {t("Group Reservation")} -{" "}
                  {props.actionMode === "I"
                    ? t("New")
                    : props.actionMode === "U"
                    ? t("Edit")
                    : props.actionMode === "K"
                    ? t("Check-In")
                    : props.actionMode === "O"
                    ? t("Check-Out")
                    : ""}
                </h4>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <GroupReservationHeaderDetails
              selectedReservation={selectedGroupReservation}
              count={count}
            />
            <Row>
              <Col
                xs={12}
                className="border-end"
                style={{ minHeight: "440px" }}
              >
                <Tabs
                  defaultActiveKey="group"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e) => {
                    // setSelectedTab(e);
                  }}
                >
                  <Tab eventKey="group" title={t("Group General Info")}>
                    <GroupTab
                      errors={formik.errors}
                      touched={formik.touched}
                      updateFields={(field, value) => {
                        formik.setFieldValue(field, value);
                      }}
                      values={formik.values}
                    />
                  </Tab>
                  <Tab eventKey="rate" title={t("Rate Info")}>
                    <RateTab
                      actionMode={props.actionMode}
                      errors={formik.errors}
                      touched={formik.touched}
                      updateFields={(field, value) => {
                        formik.setFieldValue(field, value);
                      }}
                      values={formik.values}
                    />
                  </Tab>
                  <Tab eventKey="roomTypes" title={t("Rooms & Pax Settings")}>
                    <TableRoomsTypes
                      formikSetErrors={formik.setErrors}
                      parent={formik.values}
                      formValidation={async () => await formik.validateForm()}
                      requestReservationTable={requestReservationTable}
                    />
                  </Tab>
                  <Tab eventKey={"comments"} title={t("Comments")}>
                    <Container>
                      <Row>
                        <Col xs={12}>
                          <CRUDPageGRIDCustom
                            getEndpoint="api/GroupReservations/ReservationsCommentHistoryRecords"
                            modelEndpoint="api/GroupReservations/ReservationsCommentHistoryModel"
                            addEditEndpoint="api/GroupReservations/ReservationsCommentHistoryAddEdit"
                            deleteEndpoint="api/GroupReservations/ReservationsCommentHistoryDelete"
                            detailsEndpoint="api/GroupReservations/ReservationsCommentHistoryDetails"
                            headerEndpoint="api/GroupReservations/ReservationsCommentHistoryHeader"
                            ParentGUID={selectedGroupReservation?.GUID}
                            Parent={selectedGroupReservation}
                            ParentField={"GUIDReservation"}
                            ParentFieldValue={selectedGroupReservation?.GUID}
                            ParentFieldType={"string"}
                            page={{
                              PageIndex: 2,
                              PageTitle: t("Comments"),
                              PageIcon: "fa-solid fa-file-lines",
                              MultiRecord: true,
                              Active: true,
                            }}
                          ></CRUDPageGRIDCustom>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              </Col>
              <Col md={12} className="mt-3">
                <GuestReservationList
                  data={reservations}
                  requestReservationTable={requestReservationTable}
                  listCount={({ Rooms, Adults, Children }) => {
                    console.log("List Count", Rooms, Adults, Children);
                    // setCount({ Rooms, Adults, Children })
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ maxHeight: "20vh" }} className="py-0">
            <Row className="w-100">
              <Col></Col>
              <Col className="text-end">
                {formik.errors?.length > 0 && (
                  <span className="text-danger">
                    {t("Missing required fields. Please check the form.")}
                  </span>
                )}
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={() => handleSave(formik.values)}
                  type="button"
                >
                  <i className="fa-solid fa-floppy-disk me-2" /> {t("Save")}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default GroupReservationDetails;
