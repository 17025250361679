import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { GetDataOfJSON } from '../../../utils/GetDataOfJSON';
import API from '../../../api/api';
import { parsedUser } from '../../../utils/GetCurrentUser';
import { useFormik } from 'formik';
import yupTypeValidator from '../../../utils/YupTypeValidator';
import * as yup from 'yup';
import { GenericFields } from '../../group-reservation/GenericFields';
import { RoomOverviewChangeStatusFields } from '../fields/RoomOverviewFilterFields';
import { useToast } from '../../../contexts/toast';
import { getIconsByType } from './RoomOverviewTableDetails';

const RoomOverviewChangeStatusModal = ({
  show,
  handleClose,
  roomList
}) => {
  const user = parsedUser();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [fields] = useState(RoomOverviewChangeStatusFields);
  const [formikInitialObject, setFormikInitialObject] = useState({});
  const [validationSchema, setValidationSchema] = useState(null);

  const ChangeStatus = async ({Idroom, IdRoomStatus}) => {
    try {
      setIsLoading(true);
      if (Idroom === null || Idroom === undefined) {
        throw new Error("At least a Room is required");
      }
      if (IdRoomStatus === null || IdRoomStatus === undefined) {
        throw new Error("Room Status is required");
      }
      const queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${"U"}`;
      const queryData = {
        Data: `@IdRoom=${Idroom},@IdRoomStatus=${IdRoomStatus}`,
      };

      const requestAPI = await API.postAction("/api/AutomaticTasksAssigments/HousekeepingRoomCleaning?" + queryString, queryData);
      // let parsedData = JSON.parse(requestAPI.data[0].JSONData);
      // console.log("🚀 ~ requestRecords ~ parsedData:", parsedData)
      const newData = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0).Records;
      console.log(newData);
      toast({
        title: "Success",
        message: "Status changed successfully",
        type: "success",
      })
      handleClose();
    } catch (error) {
      toast({
        title: "Error",
        message: error.message || "An error occurred",
        type: "error",
      })
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    console.log(values);
    await roomList.forEach( async (room) => {
      await ChangeStatus({Idroom: room.IdRoom, IdRoomStatus: values.IdRoomStatus});
      console.log({Idroom: room.IdRoom, IdRoomStatus: values.IdRoomStatus});
    });
    handleClose();
  }

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    setFormikInitialObject(initialObject);
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    prepareFormikObject();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The following room(s) will change status:</p>
        <div style={{maxHeight: '150px', overflowY: 'auto'}}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Room</th>
                <th>Current Status</th>
                <th>Arrv/Dep</th>
              </tr>
            </thead>
            <tbody>
              {roomList.map((room, index) => {
                return (
                <tr key={index}>
                  <td>{room.Room}</td>
                  <td>{room.st}</td>
                  <td>
                  {getIconsByType(room).map((icon, index) => (
                    <i key={index} className={`fs-4 mx-2 ${icon}`} />
                  ))}
                  </td>
                </tr>
              )})}
            </tbody>
          </Table>
        </div>
        <GenericFields 
          updateFields={updateField}
          fields={fields}
          errors={formik.errors}
          touched={formik.touched}
          values={formik.values}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-outline-secondary' onClick={handleClose} disabled={isLoading}>Close</button>
        <button className='btn btn-primary' disabled={isLoading} onClick={formik.handleSubmit}>Change</button>
      </Modal.Footer>
    </Modal>
  )
}

export default RoomOverviewChangeStatusModal
