import "devextreme/data/odata/store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Pagination,
  Row, Tab, Table, Tabs
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as yup from "yup";

import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import API from "../../api/api";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { parsedUser } from "../../utils/GetCurrentUser";
import Sha256 from "../../utils/sha256";

import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import { useToast } from "../../contexts/toast";
import useFavorite from "../../hooks/useFavorite";
import CRUDPageGRIDCustom from "../../pages/crud/CRUDPageGridCustom";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { exportToExcel as GenerateXLS } from "../../utils/export-to-excel";
import { exportToPDF as GeneratePDF } from "../../utils/export-to-pdf";

import { useTranslation } from "react-i18next";
import CustomerFilter from "./CustomerFilter";

export default function CustomerContainer() {
  const { t } = useTranslation();
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();

  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null); //I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  // const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const checkAudit = localStorage.getItem("AuditInformation");
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data

  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };
  const [section, setSection] = useState(null);
  const [fields, setFields] = useState([]);
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const selectedItemRef = useRef(null);
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (filter = "") => {
      // First start loading the model
      
      setLoading(true);

      //First load records
      try {
        let query = `IdUser=${user.IdUser}&token=${user.Token}`;
        query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
        let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
        query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;
        const queryData = {
          Data: filter,
        };
        // let request = await API.getAction(`api/customers/Records`, query);
        let request = await API.postAction(`api/Customers/Records?${query}`, queryData ?? "");
        let results = request.data[0];
        let parsedResults = JSON.parse(results.JSONData)[0];

        let recordResult = parsedResults.JSONData || [];
        // let configInfo = recordResult[0].JSONConfig ? recordResult[0].JSONConfig[0] : null;
        let dataFromAPI = recordResult[0]
          ? recordResult[0].JSONConfig
            ? recordResult[0].JSONData
            : recordResult
          : [];
        console.log("Results from record", recordResult);

        //TODO: | Use JSONConfig to show specific fields
        let gridData = null;
        let gridColumns =
          dataFromAPI && dataFromAPI.length > 0
            ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
            : null;

        //! If grid data stays null, we use the data from the API

        let titles = [
          "Audit Information",
          "Added by",
          "Date Added",
          "Modified by",
          "Last Modified",
        ];

        setModel({
          ...model,

          data: {
            ...model.data,
            Columns: gridColumns,
            Rows: gridData || dataFromAPI,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

  useEffect(() => {
    setTableHeader([]);
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
    bindDataCountRemote();
  }, [params.id, idModule]);

  useEffect(() => {
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
  }, [currentPage]);

  const bindDataCountRemote = async (cleanFilter) => {
    // First start loading the model
    //setLoading(true);

    //First load records

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }
      query = query + `&Quantity=${RecordsQuantity}&pageNumber=${currentPage}`;
      let request = await API.getAction(`api/Customers/Count`, query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const bindDataModelRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      let requestSections = await API.getAction(
        `api/Customers/Model`,
        querySections
      );
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //! If grid data stays null, we use the data from the API

      let titles = [
        t("Audit Information"),
        t("Added by"),
        t("Date Added"),
        t("Modified by"),
        t("Last Modified"),
      ];
      // console.log(recordResultSection);
      setSection(recordResultSection[0]);
      setFields(
        recordResultSection[0].Fields.filter(
          (item) => !titles.includes(item.FieldTitle)
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };
  const [formSize, setFormSize] = useState("md");
  const [tableHeader, setTableHeader] = useState([]);

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${
        idModule ? "&IdModule=" + idModule : ""
      }`;
      let requestSections = await API.getAction(
        `api/Customers/Header`,
        querySections
      );
      // console.log("Header", requestSections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      // console.log("Header 2", parsedResultsSections);
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      // console.log(recordResultSection);
      setFormSize(recordResultSection[0].FormSize ?? "md");

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      // console.log(recordResultSection);

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote(objSPVersion);
  }, [checkAudit]);

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${
        user.Token
      }&RecordId=${recordId}${idModule ? "&IdModule=" + idModule : ""}`;
      let request = await API.getAction(`api/Customers/Details`, query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult = parsedResults.JSONData;
      fillFormikObject(finalResult);
      setSelectedItem(null);
    } catch (error) {
      console.error(error);
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      formik.resetForm();
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      setOpen(!open);

      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote(objSPVersion);
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${
              selectedItem.GUID
            }${idModule ? "&IdModule=" + idModule : ""}`;
            await API.getAction(`api/Customers/Delete`, query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", section);
    if (section && fields && fields.length > 0) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    // console.log("Preparing formik object", section);
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj);
    console.log({ fields: fields });
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "password") {
        let passwordEncrypted = null;
        if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
          passwordEncrypted =
            obj["UserName"].toUpperCase() + obj[item.FieldName];
          passwordEncrypted = Sha256.hash(passwordEncrypted);
        }
        objSPVersion +=
          obj[item.FieldName] === undefined
            ? "null"
            : "'" + passwordEncrypted + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${
      actions[actionMode]
    }${idModule ? "&IdModule=" + idModule : ""}`;
    let queryData = {
      Data: objSPVersion,
    };

    let request = await API.postAction(
      `api/Customers/AddEdit?${queryString}`,
      queryData
    );

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        setGeneralError(response.Msg);
        return;
      } else {
        debugger;
        setSelectedItem(response.JSONData);
        fillFormikObject(response.JSONData);
      }
    }
    setGeneralError(null);

    if (actionMode === "I") {
      setActionMode("U");
    } else {
      toggleOpen(null, true);
    }

    toast({
      type: "success",
      message: t("Record saved successfully"),
    });
    //    toggleOpen(null, true);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${section.FormIcon}`,
      path: pathname,
      formPath: section.FormPath,
      title: section.FormTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const exportToExcel = useCallback(async () => {
    GenerateXLS(model.data.Rows, `${section.FormTitle}.xlsx`);
  }, [model.data.Rows]);

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, []);

  // END METHODS

  // UI METHODS

  useEffect(() => {
    // console.log("entro", new Date());
    // console.log({
    //   sortBy, sortByOrder, RecordsQuantity
    // })
    bindDataRemote(objSPVersion);
  }, [sortBy, sortByOrder, RecordsQuantity])

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    await bindDataRemote(obj);
  }

  const loadGRIDUI = () => {
    return (
      <>
      <CustomerFilter handleFilter={handleFilter} count={recordCount} isLoading={loading} />
        {/* {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>{t("Filters")}</Card.Title>

              <button
                className="btn btn-secondary"
                title={t("Close")}
                type="button"
                onClick={() => {
                  setIsSearching(false);
                }}
                style={{
                  padding: "0px 8px",
                  lineHeight: "23px",
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="bg-light p-0">
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder={t("Search by description")}
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  title={t("Clear")}
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  title={t("Search")}
                  type="submit"
                >
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )} */}

        <Container>
          <Row className="mx-2">
            <div className="table-responsive">
              <Table hover size="xl" bordered className="border shadow-sm">
                <thead className="table-secondary">
                  <tr>
                    {headerWithoutAuditFields().map((item, index) => {
                      // console.log(item);
                      return (
                        <th
                          key={index}
                          className={`fw-bold clickable text-nowrap`}
                          onClick={() => {
                            // console.log(item, sortBy, sortByOrder)
                            setSortBy(item.FieldName);
                            if (item.FieldName === sortBy) {
                              if (sortByOrder === "ASC") {
                                setSortByOrder("DESC");
                              } else {
                                setSortByOrder("ASC");
                              }
                            } else {
                              setSortByOrder("ASC");
                            }
                          }}
                        >
                          {item.FieldTitle}{" "}
                          {sortBy === item.FieldName &&
                          sortByOrder === "ASC" ? (
                            <i className="fas fa-sort-up"></i>
                          ) : sortBy === item.FieldName &&
                            sortByOrder === "DESC" ? (
                            <i className="fas fa-sort-down"></i>
                          ) : null}{" "}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {model?.data &&
                    model.data.Rows.map((item, index) => {
                      // console.log("item", item, "header", tableHeader);
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            setSelectedItem(item);
                          }}
                          className={
                            selectedItem && selectedItem.GUID === item.GUID
                              ? "active"
                              : ""
                          }
                        >
                          {headerWithoutAuditFields().map((column, index2) => {
                            return (
                              <td key={index2} className={`text-nowrap`}>
                                {typeof item[column.FieldName] === "boolean" ? (
                                  <input
                                    type="checkbox"
                                    checked={item[column.FieldName]}
                                    className=""
                                  />
                                ) : (
                                  item[column.FieldName]
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {model?.data?.Columns && model?.data?.Rows.length > 0 && (
                <div className="d-flex align-items-center gap-2">
                  <p className="m-0">{t("Records per page")}</p>
                  <div>
                    <select
                      className="form-control form-control-sm"
                      value={RecordsQuantity}
                      onChange={(e) => {
                        setRecordsQuantity(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      {allowedPageSizes.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {model?.data?.Columns && model?.data?.Rows.length > 0 && (
                <Pagination className="my-0">
                  <Pagination.First
                    disabled={loading}
                    onClick={() => setCurrentPage(1)}
                  />
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage <= 1 || loading}
                  />
                  <Pagination.Item disabled={loading} active>
                    {currentPage}
                  </Pagination.Item>
                  {currentPage + 1 > totalPages ? null : (
                    <Pagination.Item
                      disabled={loading}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      {currentPage + 1}
                    </Pagination.Item>
                  )}
                  {currentPage + 2 >= totalPages ? null : (
                    <Pagination.Item
                      disabled={loading}
                      onClick={() => setCurrentPage(currentPage + 2)}
                    >
                      {currentPage + 2}
                    </Pagination.Item>
                  )}
                  <Pagination.Next
                    disabled={currentPage >= totalPages || loading}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  />
                  <Pagination.Last
                    disabled={loading}
                    onClick={() => setCurrentPage(totalPages)}
                  />
                </Pagination>
              )}
            </div>
          </Row>
        </Container>
      </>
    );
  };

  //EVENTS

  useEffect(() => {
    setOpen(false);
    setSelectedItem(null);
    setActionMode(null);
    setGeneralError(null);
    setModel(initialModel);
    checkIfPinned();
    setIsSearching(false);
    setSearch("");
    bindDataModelRemote();
    bindDataHeaderRemote();
    //bindDataRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    // console.log("section updated", model);
    prepareFormikObject();
  }, [section, fields]);
  // END EVENTS
  useEffect(() => {
    // console.log("entro record quantity");
    bindDataRemote();
  }, [RecordsQuantity]);

  useEffect(() => {
    // console.log("Pages", Math.ceil(recordCount / RecordsQuantity));
    setTotalPages(Math.ceil(recordCount / RecordsQuantity));
  }, [recordCount]);
  if (!section) {
    return <></>;
  }
  return (
    <React.Fragment>
      {open ? (
        <Modal
          show={open}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size={formSize}
          className={"modalRight " + formSize}
        >
          <Modal.Header closeButton>
            <Modal.Title>{section.FormTitle}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                {/* CONTENT */}
                {fields.map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={index}
                    >
                      <label
                        className={
                          item.HtmlType === "separator"
                            ? `me-2 ${
                                index !== 0 ? "mt-4 pt-4" : "mt-2"
                              }  separatorTitle`
                            : "me-2 mt-3 "
                        }
                      >
                        {t(item.FieldTitle)}
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={actionMode}
                        model={item}
                        key={index}
                        // limit longitudes
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
              </Row>
              {/* TABS */}

              <Row>
                <Col>
                  <Tabs defaultActiveKey={"contactData"}>
                    <Tab
                      eventKey={"contactData"}
                      title={t("Contact Data")}
                      className={actionMode === "I" ? "d-none" : ""}
                    >
                      <CRUDPageGRIDCustom
                        getEndpoint="api/Customers/ContactDataRecords"
                        modelEndpoint="api/Customers/ContactDataModel"
                        addEditEndpoint="api/Customers/ContactDataAddEdit"
                        deleteEndpoint="api/Customers/ContactDataDelete"
                        detailsEndpoint="api/Customers/ContactDataDetails"
                        headerEndpoint="api/Customers/ContactDataHeader"
                        ParentGUID={formik.values?.GUID}
                        Parent={formik.values}
                        ParentField={"IdCustomer"}
                        ParentFieldValue={formik.values?.Id}
                        ParentFieldType={"int"}
                        disableAdd={actionMode === "C"}
                        disableEdit={actionMode === "C"}
                        page={{
                          PageIndex: 2,
                          PageTitle: t("Contact Data"),
                          PageIcon: "fa-solid fa-file-lines",
                          MultiRecord: true,
                          Active: true,
                        }}
                      ></CRUDPageGRIDCustom>
                    </Tab>
                    <Tab
                      eventKey={"contactPerson"}
                      title={t("Contact Person")}
                      className={actionMode === "I" ? "d-none" : ""}
                    >
                      <CRUDPageGRIDCustom
                        getEndpoint="api/Customers/ContactPersonRecords"
                        modelEndpoint="api/Customers/ContactPersonModel"
                        addEditEndpoint="api/Customers/ContactPersonAddEdit"
                        deleteEndpoint="api/Customers/ContactPersonDelete"
                        detailsEndpoint="api/Customers/ContactPersonDetails"
                        headerEndpoint="api/Customers/ContactPersonHeader"
                        ParentGUID={formik.values?.GUID}
                        Parent={formik.values}
                        ParentField={"IdCustomer"}
                        ParentFieldValue={formik.values?.Id}
                        ParentFieldType={"int"}
                        disableAdd={actionMode === "C"}
                        disableEdit={actionMode === "C"}
                        page={{
                          PageIndex: 2,
                          PageTitle: t("Contact Person"),
                          PageIcon: "fa-solid fa-file-lines",
                          MultiRecord: true,
                          Active: true,
                        }}
                      ></CRUDPageGRIDCustom>
                    </Tab>
                    {/* <Tab
                      eventKey={"folios"}
                      title={t("Folios")}
                      className={actionMode === "I" ? "d-none" : ""}
                    >
                      <CRUDPageGRIDCustom
                        getEndpoint="api/Customers/FoliosRecords"
                        modelEndpoint="api/Customers/FoliosModel"
                        addEditEndpoint="api/Customers/FoliosAddEdit"
                        deleteEndpoint="api/Customers/FoliosDelete"
                        detailsEndpoint="api/Customers/FoliosDetails"
                        headerEndpoint="api/Customers/FoliosHeader"
                        ParentGUID={formik.values?.GUID}
                        Parent={formik.values}
                        ParentField={"IdCustomer"}
                        ParentFieldValue={formik.values?.Id}
                        ParentFieldType={"int"}
                        disableAdd={actionMode === "C"}
                        disableEdit={actionMode === "C"}
                        page={{
                          PageIndex: 2,
                          PageTitle: "Folios",
                          PageIcon: "fa-solid fa-file-lines",
                          MultiRecord: true,
                          Active: true,
                        }}
                      ></CRUDPageGRIDCustom>
                    </Tab> */}
                    <Tab
                      eventKey={"commentHistory"}
                      title={t("Comment History")}
                      className={actionMode === "I" ? "d-none" : ""}
                    >
                      <CRUDPageGRIDCustom
                        getEndpoint="api/Customers/CommentHistoryRecords"
                        modelEndpoint="api/Customers/CommentHistoryModel"
                        addEditEndpoint="api/Customers/CommentHistoryAddEdit"
                        deleteEndpoint="api/Customers/CommentHistoryDelete"
                        detailsEndpoint="api/Customers/CommentHistoryDetails"
                        headerEndpoint="api/Customers/CommentHistoryHeader"
                        ParentGUID={formik.values?.GUID}
                        Parent={formik.values}
                        ParentField={"IdCustomer"}
                        ParentFieldValue={formik.values?.Id}
                        ParentFieldType={"int"}
                        disableAdd={actionMode === "C"}
                        disableEdit={actionMode === "C"}
                        page={{
                          PageIndex: 2,
                          PageTitle: t("Comment History"),
                          PageIcon: "fa-solid fa-file-lines",
                          MultiRecord: true,
                          Active: true,
                        }}
                      ></CRUDPageGRIDCustom>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    {t("Please check the forms for errors")}
                  </span>
                )}
                <button
                  className="btn  me-2"
                  type="button"
                  onClick={() => toggleOpen(null)}
                >
                  {t("Close")}
                </button>
                <button
                  className="btn btn-primary "
                  type="button"
                  onClick={() => [formik.submitForm()]}
                >
                  {actionMode === "I" ? t("Save & Continue") : t("Save")}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
      {section?.FormPath && <Breadcrumbs pathTitle={section?.FormPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className={section.FormIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{section.FormTitle}</h5>
                  </div>
                </div>
              </React.Fragment>
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className="w-auto d-flex align-items-center">
                {actionMode === null ? (
                  <>
                    <button
                      className="btn btn-sm btn-primary my-1 me-2"
                      title={t("Create")}
                      disabled={loading}
                      onClick={() => toggleOpen("I")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button
                        className={`btn btn-sm  my-1 me-2`}
                        title={t("Search")}
                        onClick={() => setIsSearching(true)}
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title={t("Update")}
                      disabled={loading}
                      onClick={() => toggleOpen("U")}
                      aria
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title={t("View")}
                      disabled={loading}
                      onClick={() => toggleOpen("C")}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </>
                ) : null}

                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        {t("Remove from favorites")}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        {t("Add to favorites")}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item
                        onClick={() => {
                          toggleOpen("D");
                        }}
                      >
                        {t("Duplicate")}
                      </Dropdown.Item>
                    ) : null}

                    {actionMode === null ? (
                      <Dropdown.Item
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        {t("Export to Excel")}
                      </Dropdown.Item>
                    ) : null}
                    {actionMode === "C" ? (
                      <Dropdown.Item onClick={() => exportToPDF()}>
                        {t("Export to PDF")}
                      </Dropdown.Item>
                    ) : null}

                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item onClick={() => deleteConfirm()}>
                        {t("Delete")}
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
