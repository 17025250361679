import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";

import "../../reservations/guest-reservation-custom-styles.css";
import { TasksAssigmentDetailsFilterFields } from "../fields/TasksAssigmentDetailsFilterFields";
import { GenericFields } from "../../group-reservation/GenericFields";

import { useTranslation } from "react-i18next";

const TasksAssigmentDetailsFilter = ({ filterRecord, count, isLoading }) => {
  const { t } = useTranslation();

  const fields = TasksAssigmentDetailsFilterFields;

  const [validationsSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (formikObj) => {
    const obj = { ...formikObj };
    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      // if (item.HtmlType === 'select') {
      //   debugger;
      // }
      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
            obj[item.FieldName] === null ||
            obj[item.FieldName] === ""
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : obj[item.FieldName] ?? "null";
      } else if (typeof item === "boolean") {
        objSPVersion += obj[item.FieldName] === true ? 1 : 0;
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
            obj[item.FieldName] === null ||
            obj[item.FieldName].length === 0 ||
            obj[item.FieldName] === "Invalid date"
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    console.log(objSPVersion);
    filterRecord(objSPVersion);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };

  return (
    <Accordion className="mb-3 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0">
          <span className="h5 m-0" style={{ fontSize: "14px" }}>
            {t("Filter Assigment")}
          </span>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-0">
          <Card className="w-100">
            <Form onSubmit={formik.handleSubmit}>
              <Card.Body className="bg-light">
                <Row>
                  <Col xs={12}>
                    <Row className="mx-0">
                      <GenericFields
                        errors={formik.errors}
                        values={formik.values}
                        updateFields={updateField}
                        fields={fields.filter((x) => x.Group === 0)}
                      />
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <div className="display-6 text-success">
                      {t("Tasks Assigment Details found:")} {count || 0}
                    </div>
                  </Col>
                  <Col xs="auto text-end">
                    <button
                      className="btn btn-sm btn-secondary me-2"
                      type="button"
                      title={t("Clear")}
                      onClick={() => formik.resetForm()}
                    >
                      <i className="fas fa-eraser"></i>
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      title={t("Search")}
                      type="submit"
                      disabled={isLoading}
                    >
                      <Search />
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TasksAssigmentDetailsFilter;
