import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../breadcrumb";
import { Button, Card, Container } from "react-bootstrap";
import TasksAssigmentDetailsFilter from "./TasksAssigmentDetailsFilter";
import Pagination from "../../Pagination/Pagination";
import moment from "moment/moment";
import TasksAssignmentPlanning from "./TasksAssignmentPlanning/TasksAssignmentPlanning";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";
import { confirmAlert } from "react-confirm-alert";

import { useTranslation } from "react-i18next";
import { useToast } from "../../../contexts/toast";
import { TaskAssignmentTableFields } from "./TaskAssignmentFields";

const TasksAssigmentDetailsContainer = ({ routeName }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [tableHeader] = useState(TaskAssignmentTableFields);
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data
  const [reloadData, setReloadData] = useState(false);

  const user = parsedUser();

  const [action, setAction] = useState("");

  const [showTasksPlanning, setShowTasksPlanning] = useState(false);

  const principalButtonList = [
    {
      id: "editBtn",
      title: t("Edit"),
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "B"],
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: "newBtn",
      title: t("New"),
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleAddEdit("P"),
    },
    {
      id: "cancelBtn",
      title: t("Cancel"),
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-times",
      ifSelectedItem: true,
      reservationCode: ["T", "C"],
      onClick: () => deleteConfirm(),
    },
  ];

  const requestRecords = async (filter) => {
    setIsLoading(true);
    try {
      // let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&PageNumber=${currentPage}&Quantity=20&SortBy=Id&SortType=DESC`;
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: filter,
      };

      console.log(queryData);
      let requestAPI = await API.postAction(
        "/api/AutomaticTasksAssigments/Records?" + query,
        queryData
      );

      let newData = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0).JSONData;

      console.log(newData);

      if (newData && newData.length > 0) {
        setRecords(newData);
      }
      await bindDataCountRemote(filter);
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: t("An error occurred while loading the records"),
        width: "auto",
        height: "auto",
      })
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    requestRecords();
  }, [showTasksPlanning]);

  const toggleAddEdit = (action) => {
    setShowTasksPlanning(!showTasksPlanning);

    setAction(action);

    if (action === "P") setSelectedItem(null);
  };

  const deleteConfirm = async () => {
    const operationDate = moment(user.OperationDate).format("YYYY-MM-DD");
    const recordDate = moment(selectedItem.TaskDate).format("YYYY-MM-DD");

    if (moment(recordDate).isBefore(operationDate)) {
      toast({
        type: "error",
        message: t("Cancelation is not allowed after the task start date."),
        width: "auto",
        height: "auto",
      })
      return;
    }

    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `IdUser=${user.IdUser}token=${user.Token}&RecordId=${selectedItem.GUID}`;
              await API.getAction(
                "api/AutomaticTasksAssigments/Delete?" + query
              );
              await requestRecords(objSPVersion);
              setSelectedItem(null);
              toast({
                type: "success",
                message: t("Cancelation was successful"),
                width: "auto",
                height: "auto",
              })
            } catch (error) {
              toast({
                type: "error",
                message: t("An error occurred while canceling"),
                width: "auto",
                height: "auto",
              })
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  const bindDataCountRemote = async (filter) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(
        `api/AutomaticTasksAssigments/Count?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    }
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    setCurrentPage(1);
    await requestRecords(obj);
  };

  // Effects

  useEffect(() => {
    requestRecords(objSPVersion);
    setSelectedItem(null);
  }, [sortBy, sortByOrder, RecordsQuantity, currentPage]);

  useEffect(() => {
    if (!showTasksPlanning) {
      requestRecords(objSPVersion);
    }
  }, [showTasksPlanning]);

  useEffect(() => {
    if (reloadData) {
      requestRecords(objSPVersion);
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    setTotalPages(Math.ceil(recordCount / RecordsQuantity));
  }, [recordCount, RecordsQuantity]);

  return (
    <>
      {showTasksPlanning ? (
        <TasksAssignmentPlanning
          show={showTasksPlanning}
          toggle={toggleAddEdit}
          selectedItem={selectedItem}
          action={action}
          title={selectedItem ? t("Edit") : t("New")}
        />
      ) : null}

      <CustomBreadcrumbs pathTitle={routeName} />

      <Container className="mt-0 pt-0">
        <div className="row mx-3">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className="dx-icon fas fa-broom me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>
                        {t("Automatic Task Assigment")}
                      </h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div
              className="py-2 z-2"
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#f2f2f2",
              }}
            >
              <div className="d-flex w-10 mt-0 justify-content-start gap-1">
                {principalButtonList.map((item, index) => {
                  // console.log(selectedItem);
                  if (item.ifSelectedItem && selectedItem) {
                    //Validate if checking is pressed

                    return (
                      <Button
                        key={index}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    );
                  }
                  if (!item.ifSelectedItem) {
                    return (
                      <Button
                        key={index}
                        variant={item.variant}
                        size="lg"
                        className={item.className}
                        onClick={item.onClick}
                      >
                        {item.title}
                        <br />
                        <i className={item.icon} />
                      </Button>
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
          <TasksAssigmentDetailsFilter
            count={recordCount}
            filterRecord={handleFilter}
          />

          <Card>
            <Card.Body>
              <div className="w-100">
                <div className="wrapper" style={{ overflowY: "auto" }}>
                  <table className="table table-bordered shadow-sm table-striped">
                    <thead>
                      <tr>
                        {headerWithoutAuditFields().map((item, index) => {
                          return (
                            <th
                              key={index}
                              className={`fw-bold clickable`}
                              onClick={() => {
                                setSortBy(item.FieldName);
                                if (item.FieldName === sortBy) {
                                  if (sortByOrder === "ASC") {
                                    setSortByOrder("DESC");
                                  } else {
                                    setSortByOrder("ASC");
                                  }
                                } else {
                                  setSortByOrder("ASC");
                                }
                              }}
                            >
                              {item.FieldTitle}{" "}
                              {sortBy === item.FieldName &&
                                sortByOrder === "ASC" ? (
                                <i className="fas fa-sort-up"></i>
                              ) : sortBy === item.FieldName &&
                                sortByOrder === "DESC" ? (
                                <i className="fas fa-sort-down"></i>
                              ) : null}{" "}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {records && records.length > 0 ? (
                        records.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              className={` clickable ${item === selectedItem ? " active" : ""
                                }`}
                              onClick={() => setSelectedItem(item)}
                            >
                              <td className="text-start">{item.AutomaticTaskType}</td>
                              <td>{moment(item.TaskDate).format("MM/DD/YYYY")}</td>
                              <td className="text-nowrap">{item.BusinessUnit}</td>
                              <td>{item.TotalAttendants}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="border-1 text-center" colSpan={tableHeader.length}>
                            {t("No Records Found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  isLoading={isLoading}
                  RecordsQuantity={RecordsQuantity}
                  recordCount={recordCount}
                  // totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsQuantity={setRecordsQuantity}
                />

              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default TasksAssigmentDetailsContainer;
