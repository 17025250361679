import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const CollaboratorsRoomsAssignment = ({ tasksDetail, tasksCard }) => {
  const { t } = useTranslation();

  const [tasksNumbers, setTasksNumbers] = useState([]);

  useEffect(() => {
    getTaskNumbers();
  }, []);

  const getTaskNumbers = () => {
    setTasksNumbers([...new Set(tasksCard.map((item) => item.TaskNumber))]);
  };

  const getIconByType = (icon) => {
    if (icon.Arrival) return "fa-solid fa-arrow-right-to-bracket";
    if (icon.Departure) return "fa-solid fa-door-open";
    if (icon.Stayover) return "fa-solid fa-person-arrow-down-to-line";
    return "";
  };

  const Room = ({ textColor, bgColor, room }) => {
    return (
      <div className="d-flex justify-content-start align-items-center">
        <div
          style={{ color: "white", backgroundColor: "#B1B9C6" }}
          className="text-center py-1 w-75
             me-4 fs-4 rounded"
        >
          {room.Room}
        </div>
        <div>
          <i className={`fs-4 ${getIconByType(room)}`} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Row
        className="d-flex flex-nowrap overflow-scroll"
        style={{ minHeight: "500px", maxHeight: "600px" }}
      >
        {tasksNumbers.map((taskNumber, index) => {
          let rooms = tasksCard.filter(
            (item) => taskNumber === item.TaskNumber
          );

          let detail =
            tasksDetail && tasksDetail.length > 0
              ? tasksDetail.filter((item) => taskNumber === item.TaskNumber)[0]
              : null;

          return (
            <Col xs={3} key={index} className="mb-4 h-full">
              <Card>
                <Card.Header
                  className="text-white"
                  style={{ backgroundColor: "#0A1633" }}
                >
                  <Card.Title className="text-center">
                    {t("Collaborator")} - {index + 1}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {rooms.map((room, index) => (
                      <Col
                        key={index}
                        xs={12}
                        className={`pb-3 ${index > 0 ? "mt-3" : null} ${
                          index === rooms.length - 1 ? null : "shadow-sm"
                        }`}
                      >
                        <Room
                          key={index}
                          textColor={null}
                          bgColor={null}
                          room={room}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end fw-bold">
                  {t("Total Rooms:")} {detail.TotalRooms}
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default CollaboratorsRoomsAssignment;
