import "devextreme/data/odata/store";
import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as yup from "yup";

import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import FieldSelector from "../customComponents/FieldSelector";

import { CustomBreadcrumbs as Breadcrumbs } from "..";
import { useToast } from "../../contexts/toast";
import useFavorite from "../../hooks/useFavorite";
import CRUDPageGRIDCustom from "../../pages/crud/CRUDPageGridCustom";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { exportToExcel as GenerateXLS } from "../../utils/export-to-excel";
import { exportToPDF as GeneratePDF } from "../../utils/export-to-pdf";

import { useTranslation } from "react-i18next";
import VirtualRoomFilter from "./VirtualRoomFilter";
import Pagination from "../Pagination/Pagination";

export default function VirtualRoomContainer() {
  const { t } = useTranslation();

  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();

  const [recordCount, setRecordCount] = useState(0);
  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null); //I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const checkAudit = localStorage.getItem("AuditInformation");
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data

  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };
  const [section, setSection] = useState(null);
  const [fields, setFields] = useState([]);
  const [model, setModel] = useState(initialModel);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  const selectedItemRef = useRef(null);
  const [sortBy, setSortBy] = useState("Id");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (filter) => {
    setLoading(true);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(
        "api/virtualRoom/virtualRoomRecords?" + query,
        queryData ?? ""
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;

      let recordResult = parsedResults;

      if (recordResult === null) {
        setModel({
          ...model,

          data: {
            ...model.data,

            Rows: [],
          },
        });

        return;
        // throw new Error("No data found");
      }

      // Migrate to the new model
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];
      console.log("Results from record", recordResult);

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

      await bindDataCountRemote();

      // setData(recordResult)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTableHeader([]);
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
  }, [params.id, idModule]);

  useEffect(() => {
    bindDataRemote(objSPVersion);
    setSelectedItem(null);
  }, [currentPage]);

  const bindDataCountRemote = async (cleanFilter) => {
    // First start loading the model

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""
        }`;

      query = query + `&Quantity=${RecordsQuantity}&pageNumber=${currentPage}`;

      let queryData = {
        Data: "",
      };

      let request = await API.postAction(
        `api/VirtualRoom/VirtualRoomCount?${query}`,
        queryData
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const bindDataModelRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""
        }`;
      let requestSections = await API.getAction(
        `api/VirtualRoom/VirtualRoomModel`,
        querySections
      );
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //! If grid data stays null, we use the data from the API

      let titles = [
        t("Audit Information"),
        t("Added by"),
        t("Date Added"),
        t("Modified by"),
        t("Last Modified"),
      ];
      console.log(recordResultSection);
      setSection(recordResultSection[0]);
      setFields(
        recordResultSection[0].Fields.filter(
          (item) => !titles.includes(item.FieldTitle)
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };
  const [formSize, setFormSize] = useState("md");
  const [tableHeader, setTableHeader] = useState([]);

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""
        }`;
      let requestSections = await API.getAction(
        `api/virtualRoom/VirtualRoomHeader`,
        querySections
      );
      console.log("Header", requestSections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      console.log("Header 2", parsedResultsSections);
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      console.log(recordResultSection);
      setFormSize(recordResultSection[0].FormSize ?? "md");

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      // console.log(recordResultSection);

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, [checkAudit]);

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token
        }&RecordId=${recordId}${idModule ? "&IdModule=" + idModule : ""}`;
      let request = await API.getAction(
        `api/VirtualRoom/VirtualRoomDetails`,
        query
      );
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult = parsedResults.JSONData;
      fillFormikObject(finalResult);
      setSelectedItem(null);
    } catch (error) {
      console.error(error);
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      formik.resetForm();
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      setOpen(!open);

      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedItem.GUID
              }${idModule ? "&IdModule=" + idModule : ""}`;
            await API.getAction(`api/VirtualRoom/VirtualRoomDelete`, query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", section);
    if (section && fields && fields.length > 0) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", section);
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actions[actionMode]
      }${idModule ? "&IdModule=" + idModule : ""}`;
    let queryData = {
      Data: objSPVersion,
    };

    let request = await API.postAction(
      `api/VirtualRoom/VirtualRoomAddEdit?${queryString}`,
      queryData
    );

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        setGeneralError(response.Msg);
        toast({
          type: "error",
          message: response.Msg || t("An error occurred"),
        })
        return;
      } else {
        // console.log("formik", formik.values);
        // debugger;
        setSelectedItem(response.JSONData);

        if (actionMode === "D") {
          // Means we are duplicating
          var responseRoom = await API.getAction(
            `api/VirtualRoom/Duplicate`,
            `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${response.JSONData.Id}&GUID=${response.JSONData.GUID}&previousRecord=${formik.values.Id}`
          );
        }
        fillFormikObject(response.JSONData);
      }
    }
    setGeneralError(null);

    if (actionMode === "I") {
      setActionMode("U");
    } else {
      toggleOpen(null, true);
    }

    toast({
      type: "success",
      message: t("Record saved successfully"),
    });
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${section.FormIcon}`,
      path: pathname,
      formPath: section.FormPath,
      title: section.FormTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const exportToExcel = useCallback(async () => {
    GenerateXLS(model.data.Rows, `${section.FormTitle}.xlsx`);
  }, [model.data.Rows]);

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, []);

  // END METHODS

  // UI METHODS

  useEffect(() => {
    bindDataRemote();
  }, [sortBy, sortByOrder]);

  const handleFilter = (obj) => {
    bindDataRemote(obj);
    setObjSPVersion(obj)
  }

  const loadGRIDUI = () => {
    return (
      <>
        <VirtualRoomFilter
          handleFilter={handleFilter}
          count={recordCount}
          isLoading={loading}
        />
        <Container>
          <Card>
            <Card.Body>
              <Row>
                <div className="table-responsive">
                  <Table hover size="xl" bordered className="table table-bordered shadow-sm table-striped">
                    <thead>
                      <tr>
                        {headerWithoutAuditFields().map((item, index) => {
                          return (
                            <th
                              key={index}
                              className={`fw-bold clickable`}
                              onClick={() => {
                                setSortBy(item.FieldName);
                                if (item.FieldName === sortBy) {
                                  if (sortByOrder === "ASC") {
                                    setSortByOrder("DESC");
                                  } else {
                                    setSortByOrder("ASC");
                                  }
                                } else {
                                  setSortByOrder("ASC");
                                }
                              }}
                            >
                              {item.FieldTitle}{" "}
                              {sortBy === item.FieldName &&
                                sortByOrder === "ASC" ? (
                                <i className="fas fa-sort-up"></i>
                              ) : sortBy === item.FieldName &&
                                sortByOrder === "DESC" ? (
                                <i className="fas fa-sort-down"></i>
                              ) : null}{" "}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {model?.data &&
                        model.data.Rows.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedItem(item);
                              }}
                              className={
                                selectedItem && selectedItem.GUID === item.GUID
                                  ? "active"
                                  : ""
                              }
                            >
                              {headerWithoutAuditFields().map((column, index2) => {
                                return (
                                  <td key={index2} className={` `}>
                                    {typeof item[column.FieldName] === "boolean" ? (
                                      <input
                                        type="checkbox"
                                        checked={item[column.FieldName]}
                                        className=""
                                      />
                                    ) : (
                                      item[column.FieldName]
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                <Pagination
                  isLoading={loading}
                  RecordsQuantity={RecordsQuantity}
                  recordCount={recordCount}
                  // totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setRecordsQuantity={setRecordsQuantity}
                />
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  };

  //EVENTS

  useEffect(() => {
    setOpen(false);
    setSelectedItem(null);
    setActionMode(null);
    setGeneralError(null);
    setModel(initialModel);
    checkIfPinned();
    bindDataModelRemote();
    bindDataHeaderRemote();
    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    prepareFormikObject();
  }, [section, fields]);
  // END EVENTS
  useEffect(() => {
    bindDataRemote(objSPVersion);
  }, [RecordsQuantity]);

  return (
    <React.Fragment>
      {open ? (
        <Modal
          show={open}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size={formSize}
          className={"modalRight " + formSize}
        >
          <Modal.Header closeButton>
            <Modal.Title>{section.FormTitle}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                {/* CONTENT */}
                {fields.map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={index}
                    >
                      <label
                        className={
                          item.HtmlType === "separator"
                            ? `me-2 ${index !== 0 ? "mt-4 pt-4" : "mt-2"
                            }  separatorTitle`
                            : "me-2 mt-3 "
                        }
                      >
                        {t(item.FieldTitle)}
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={actionMode}
                        model={item}
                        key={index}
                        obj={formik.values}
                        // limit longitudes
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
              </Row>
              {/* TABS */}
              {console.log(formik.values, actionMode)}
              {actionMode !== "I" && formik.values.Id && (
                <Row>
                  <Col>
                    <CRUDPageGRIDCustom
                      getEndpoint="api/VirtualRoom/RoomVirtualToRoomRecords"
                      modelEndpoint="api/VirtualRoom/RoomVirtualToRoomModel"
                      addEditEndpoint="api/VirtualRoom/RoomVirtualToRoomAddEdit"
                      deleteEndpoint="api/VirtualRoom/RoomVirtualToRoomDelete"
                      detailsEndpoint="api/VirtualRoom/RoomVirtualToRoomDetails"
                      headerEndpoint="api/virtualRoom/RoomVirtualToRoomHeader"
                      ParentGUID={formik.values?.GUID}
                      Parent={formik.values}
                      ParentField={"IdRoomVirtual"}
                      ParentFieldValue={formik.values?.Id}
                      ParentFieldType={"int"}
                      page={{
                        PageIndex: 2,
                        PageTitle: t("Rooms"),
                        PageIcon: "fa-solid fa-file-lines",
                        MultiRecord: true,
                        Active: true,
                      }}
                      customField="IdBusinessUnit"
                      customFieldValue={formik.values.IdBusinessUnit}
                      id="RoomVirtualToRoom"
                      disableAdd={actionMode === "C"}
                      disableEdit={actionMode === "C"}
                    ></CRUDPageGRIDCustom>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                )}
                <button
                  className="btn  me-2"
                  type="button"
                  onClick={() => toggleOpen(null)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary "
                  type="button"
                  onClick={() => [formik.submitForm()]}
                >
                  {actionMode === "I" ? "Save & Continue" : "Save"}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
      {section?.FormPath && <Breadcrumbs pathTitle={section?.FormPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className={section?.FormIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{section?.FormTitle}</h5>
                  </div>
                </div>
              </React.Fragment>
              {loading && (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
                {actionMode === null ? (
                  <>
                    <button
                      className="btn btn-sm btn-primary my-1 me-2"
                      title="Create"
                      disabled={loading}
                      onClick={() => toggleOpen("I")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title="Update"
                      disabled={loading}
                      onClick={() => toggleOpen("U")}
                      aria
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button
                      className="btn btn-sm  my-1 me-2"
                      title="View"
                      disabled={loading}
                      onClick={() => toggleOpen("C")}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </>
                ) : null}

                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        Remover de favoritos
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          addFavorite();
                        }}
                      >
                        Agregar a favoritos
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item
                        onClick={() => {
                          toggleOpen("D");
                        }}
                      >
                        Duplicar
                      </Dropdown.Item>
                    ) : null}

                    {actionMode === null ? (
                      <Dropdown.Item
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    ) : null}
                    {actionMode === "C" ? (
                      <Dropdown.Item onClick={() => exportToPDF()}>
                        Export to PDF
                      </Dropdown.Item>
                    ) : null}

                    <Dropdown.Divider />
                    {selectedItem ? (
                      <Dropdown.Item onClick={() => deleteConfirm()}>
                        Delete
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
