import React, { useEffect, useState } from "react";
import { Form, Row, Card, Col, Container } from "react-bootstrap";
import * as yup from "yup";

import RatePeriodContractTab from "../RatePeriodTab/RatePeriodTab";
import FieldSelector from "../../customComponents/FieldSelector";
import ContractFields from "../Fields/ContractFieldsV3";
import { useFormik } from "formik";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import ContractPoliciesTab from "../ContractPoliciesTab/ContractPoliciesTab";
import { useToast } from "../../../contexts/toast";
import CommentHistoryFields from "../Fields/CommentHistoryFields";
import "semantic-ui-css/semantic.min.css";
import { Step } from "semantic-ui-react";
import CRUDPageGRIDCustom from "../../../pages/crud/CRUDPageGridCustom";
import SupplementsTab from "./Supplements/Supplements.js";
import SpecialRate from "./SpecialRate/SpecialRate.js";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const ContractForm = (props) => {
  const user = parsedUser();
  const toast = useToast();
  const [contractGUID, setContractGUID] = useState(null);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [fields, setFields] = useState(ContractFields);
  const [actionMode, setActionMode] = useState(props.actionMode);
  const [record, setRecord] = useState({});

  // Prepare Formik Object
  const prepareFormikObject = () => {
    // console.log("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        // if (item.FieldName === 'IdCurrency') {
        //   debugger;
        // }
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    // console.log("formik initial value", initialObject);
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const fillFormikObject = (record) => {
    // console.log("record", record, "model", fields);
    if (fields) {
      fields.forEach((item) => {
        // console.log(
        //   "field name",
        //   item.FieldName,
        //   "RecordValue ",
        //   record[item.FieldName]
        // );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }
  };

  // Yup Type Validator
  const yupTypeValidator = (item) => {
    const { FieldType, FieldTitle } = item;
    if (FieldType === "string" || FieldType === "varchar") {
      return yup.string().label(FieldTitle).required();
    } else if (FieldType === "bool" || FieldType === "bit") {
      return yup.boolean().label(FieldTitle).required();
    } else if (FieldType === "int" || FieldType === "bigint") {
      return yup.number().label(FieldTitle).required();
    } else if (FieldType === "date") {
      return yup.date().label(FieldTitle).required();
    }
  };

  // SUBMIT FORM
  const handleSubmit = async (values, arg, avoidStep) => {
    console.log(values, record, props);

    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.HtmlType === "separator") return;

      if (item.HtmlType === "date") {
        if (values[item.FieldName] === "Invalid date") {
          values[item.FieldName] = null;
        }
      }

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          values[item.FieldName] === undefined ||
            values[item.FieldName] === null ||
            values[item.FieldName] === ""
            ? item.DefaultValue && item.DefaultValue > 0
              ? item.DefaultValue
              : "null"
            : "'" + values[item.FieldName] + "'";
        // if (item.FieldName === 'IdCurrency') {
        //   debugger;
        // }
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          values[item.FieldName] === undefined ||
            values[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + values[item.FieldName] + "'";
      } else {
        objSPVersion +=
          values[item.FieldName] === undefined ||
            values[item.FieldName] === null
            ? "null"
            : "'" + values[item.FieldName] + "'";
      }
    });
    console.log(objSPVersion);
    if (actionMode === "U") {
      objSPVersion += `, @GUID='${props.data.GUID}'`;
    }

    let queryString = `ActionMode=${actionMode}&IdUser=${user.IdUser}&Token=${user.Token}`;

    let queryData = {
      Data: objSPVersion,
    };
    let request = await API.postAction(
      `api/Contracts/ContractAddEdit?${queryString}`,
      queryData
    );

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData);
      if (response.Error) {
        alert(response.Msg);
        return;
      }

      // console.log("response,", response)
      let theGUID = response[0].JSONData.GUID;
      // props.setSelectedItem(response[0].JSONData);
      setContractGUID(theGUID);
      // console.log("the guid", theGUID)
      //   formik.resetForm();

      toast({
        type: "success",
        message: t("Record saved successfully"),
      });
    }
    setActionMode("U");
    if (!avoidStep) {
      setStep(step + 1);
    }
  };

  // CLOSE FORM
  const handleClose = () => {
    props.refreshTable();
    props.clearSelection(null);
    props.setShow(false);
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const UpdateRatePeriod = (obj) => {
    setRecord({ ...record, BusinessUnits: obj });
  };

  const [step, setStep] = useState(0);

  useEffect(() => {
    // console.log("sent selected data", props)
    if (actionMode === "U" || actionMode === "C") {
      fillFormikObject(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  //This use effect is to be used with the intention of always have the proper selected element
  useEffect(() => {
    if (contractGUID) {
      bindDataDetailsRemote();
    }
  }, [contractGUID]);

  const handleSteps = async (nextStep) => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      formik.setTouched(errors);
      return;
    }

    if (step === 0) {
      handleSubmit(formik.values, null, true);
    }

    setStep(nextStep);
  };

  const bindDataDetailsRemote = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${contractGUID}`;
      let request = await API.getAction("api/Contracts/ContractDetails", query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;

      if (recordResult === null) {
        throw new Error(t("No data found"));
      }

      props.setSelectedItem(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  return (
    <div className="">
      <Row>
        <Col xs={12} className="mb-4">
          <Step.Group widths={6}>
            <Step active={step === 0} onClick={() => setStep(0)}>
              <Step.Content>
                <Step.Title>{t("General")}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={step === 1}
              disabled={step === 0 && actionMode === "I"}
              onClick={() => handleSteps(1)}
            >
              <Step.Content>
                <Step.Title>{t("Rates Period")}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={step === 2}
              disabled={step === 0 && actionMode === "I"}
              onClick={() => handleSteps(2)}
            >
              <Step.Content>
                <Step.Title>{t("Default Rates Policies")}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={step === 3}
              disabled={step === 0 && actionMode === "I"}
              onClick={() => handleSteps(3)}
            >
              <Step.Content>
                <Step.Title>{t("Rates Comments")}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={step === 4}
              disabled={step === 0 && actionMode === "I"}
              onClick={() => handleSteps(4)}
            >
              <Step.Content>
                <Step.Title>{t("Supplements")}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={step === 5}
              disabled={step === 0 && actionMode === "I"}
              onClick={() => handleSteps(5)}
            >
              <Step.Content>
                <Step.Title>{t("Rate Discounts")}</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Col>
      </Row>
      <Form onSubmit={formik.submitForm}>
        {step === 0 ? (
          <Card className="mb-4">
            <Card.Body className="p-2 px-4">
              <Container>
                <Row>
                  {ContractFields.filter((x) => x.Visible).map(
                    (item, index) => (
                      <div key={index} className={`${item.cssClasss} mb-4`}>
                        <label
                          className={
                            item.HtmlType === "separator"
                              ? `me-2 ${index !== 0 ? "mt-4 pt-4" : "mt-2"
                              }  separatorTitle`
                              : "me-2 mt-3 "
                          }
                        >
                          {t(item.FieldTitle)}
                          {item.Required ? (
                            <i className="required-asterisk ms-1 text-danger">
                              *
                            </i>
                          ) : null}
                        </label>
                        <br />
                        <FieldSelector
                          model={item}
                          obj={formik.values}
                          updateField={updateField}
                          //  getFieldValue={getFieldValue}
                          data={props.data}
                          action={props.action}
                          value={
                            formik.values[item.FieldName] !== undefined
                              ? formik.values[item.FieldName]
                              : item.value
                          }
                        />
                        {formik.errors[item.FieldName] && (
                          <div className="invalid text-sm">
                            {formik.errors[item.FieldName]}
                          </div>
                        )}
                      </div>
                    )
                  )}
                </Row>
              </Container>
            </Card.Body>
          </Card>
        ) : null}
        {step === 1 ? (
          <RatePeriodContractTab
            update={UpdateRatePeriod}
            contractGUID={contractGUID}
            Contract={formik.values}
          />
        ) : null}
        {step === 2 ? (
          <ContractPoliciesTab
            contractGUID={contractGUID}
            page={{
              PageIndex: 2,
              PageTitle: t("Rates Policies"),
              PageIcon: "fa-solid fa-file-lines",
              MultiRecord: true,
              Active: true,
            }}
          />
        ) : null}
        {step === 3 ? (
          <CRUDPageGRIDCustom
            getEndpoint="api/Contracts/CommentHistoryRecords"
            modelEndpoint="api/Contracts/CommentHistoryModel"
            addEditEndpoint="api/Contracts/CommentHistoryAddEdit"
            deleteEndpoint="api/Contracts/CommentHistoryDelete"
            detailsEndpoint="api/Contracts/CommentHistoryDetails"
            headerEndpoint="api/contracts/CommentHistoryHeader"
            fields={CommentHistoryFields}
            ParentGUID={contractGUID}
            page={{
              PageIndex: 2,
              PageTitle: `${t("Rates Comments")}`,
              PageIcon: "fa-solid fa-file-lines",
              MultiRecord: true,
              Active: true,
            }}
          />
        ) : null}
        {step === 4 ? (
          <SupplementsTab
            Contract={formik.values}
            modalSize={"lg"}
            ParentGUID={contractGUID}
            page={{
              PageIndex: 2,
              PageTitle: t("Supplements"),
              PageIcon: "fa-solid fa-file-lines",
              MultiRecord: true,
              Active: true,
            }}
          />
        ) : null}
        {step === 5 ? (
          <SpecialRate
            Contract={formik.values}
            modalSize={"lg"}
            contractGUID={contractGUID}
            page={{
              PageIndex: 2,
              PageTitle: t("Special Rate"),
              PageIcon: "fa-solid fa-file-lines",
              MultiRecord: true,
              Active: true,
            }}
          />
        ) : null}

        <Card className="my-4">
          <Card.Body className="p-2">
            <Row className="">
              <div className="d-flex gx-3 align-items-center justify-content-end">
                <button
                  className="btn me-2"
                  type={"button"}
                  onClick={handleClose}
                >
                  {t("Close")}
                </button>
                {step > 0 ? (
                  <button
                    className="btn btn-secondary me-2"
                    type={"button"}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    {t("Prev")}
                  </button>
                ) : null}
                {step < 5 ? (
                  <button
                    className="btn btn-primary me-2"
                    type={"button"}
                    onClick={async () => {
                      await handleSubmit(formik.values);
                      handleClose();

                    }}
                  >
                    {t("Save & close")}
                  </button>
                ) : null}
                {step < 5 ? (
                  <button
                    className="btn btn-secondary me-2"
                    type={"button"}
                    onClick={async () => {
                      if (step === 0) {
                        formik.submitForm();
                      } else {
                        setStep(step + 1);
                      }
                    }}
                  >
                    {t("Next")}
                  </button>
                ) : null}
                {step === 5 ? (
                  <button
                    className="btn btn-primary me-2"
                    type={"button"}
                    onClick={handleClose}
                  >
                    {t("Finish")}
                  </button>
                ) : null}
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
};

export default ContractForm;
