import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import FieldSelector from "../customComponents/FieldSelector";
import { CheckBox } from "devextreme-react";

import { useTranslation } from "react-i18next";

const ReservationSecFilter = ({ filterBy }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    FSAll: false,
    FSInhouse: true,
    FSReserved: true,
    FSCheckedOut: false,
    FSNoShow: false,
    FSCanceled: false,
    FSBumpedOut: false,
  });

  useEffect(() => {
    filterBy(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleFilter = (name, checked) => {
    // const { name, checked } = e.target

    // Select all
    if (name === "FSAll") {
      setFilter({
        FSAll: checked,
        FSInhouse: checked,
        FSReserved: checked,
        FSCheckedOut: checked,
        FSNoShow: checked,
        FSCanceled: checked,
        FSBumpedOut: checked,
      });
      return;
    }

    // Unselect 'all' if any other is unselected
    if (name !== "FSAll" && filter.FSAll) {
      setFilter({
        ...filter,
        FSAll: false,
        [name]: checked,
      });
      return;
    }

    // select all if all are selected and the default case.
    setFilter((prev) => {
      const updatedFilter = {
        ...prev,
        [name]: checked,
      };

      if (
        name !== "FSAll" &&
        Object.keys(updatedFilter)
          .filter((key) => key !== "FSAll")
          .every((key) => updatedFilter[key]) &&
        !updatedFilter.FSAll
      ) {
        updatedFilter.FSAll = true;
      }

      return updatedFilter;
    });
  };

  const Checkboxes = [
    {
      label: t("All"),
      name: "FSAll",
    },
    {
      label: t("Inhouse"),
      name: "FSInhouse",
    },
    {
      label: t("Reserved"),
      name: "FSReserved",
    },
    {
      label: t("Checked Out"),
      name: "FSCheckedOut",
    },
    {
      label: t("No show"),
      name: "FSNoShow",
    },
    {
      label: t("Canceled"),
      name: "FSCanceled",
    },
    {
      label: t("Bumped Out"),
      name: "FSBumpedOut",
    },
  ];

  return (
    <Row className="mx-0 p-2 border">
      <Col>
        {Checkboxes.map((item, index) => (
          <Col key={index}>
            <Row>
              <Col>
                <label className="me-2 mt-2">{t(item.label)}</label>
              </Col>
              <Col className="d-flex justify-content-end">
                <CheckBox
                  // className='mt-2'
                  name={item.name}
                  value={filter[item.name]}
                  onValueChange={(e) => {
                    handleFilter(item.name, e);
                  }}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Col>
    </Row>
  );
};

export default ReservationSecFilter;
