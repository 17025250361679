const exceptions = ["Row", "Id", "IdRoom", "IdRoomBlockType", "IdDepartment", "StateBgColor", "StateTxtColor", "RowStatus", "IdUserAdd", "IdUserMod",	"DateAdd",	"DateMod",	"DateDel",	"GUID"]

export class ExcelRecordMapper {
  static mapRecord = (record) => {
    const mappedRecord = {};
    for (const key in record) {
      if (!exceptions.includes(key)) {
        mappedRecord[key] = record[key];
      }
    }
    return mappedRecord;
  };
}
