import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";
import Pagination from "../Pagination/Pagination";
import { GroupReservationTableFields } from "./FieldsGroup/ReservationTableFields";
import GroupReservationFilter from "./GroupReservationFilter";
import moment from "moment";

import { useTranslation } from "react-i18next";

const GroupReservationContainer = ({
  showUnCancelReservation,
  showCancelReservation,
  selectedItem,
  setSelectedItem,
  showAddEdit,
  setReloadData,
  reloadData,
}) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };

  const [model, setModel] = useState(initialModel);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [tableHeader, setTableHeader] = useState(GroupReservationTableFields);
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("ReservationGroupNumber");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data
  // End Properties

  // METHODS
  const bindDataRemote = async (filter) => {
    if (isLoading === true) return;
    // First start loading the model
    setIsLoading(true);
    // filter = `${filter}, @IdBusinessUnit=${user.IdBusinessUnit}`
    if (filter === undefined || filter === null || filter === "") {
      filter = `@IdBusinessUnit=${user.IdBusinessUnit}`;
    }

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;
      let prefix = "GroupReservations";
      let queryData = {
        Data: filter,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/${prefix}/ReservationRecords?` + query,
        queryData ?? ""
      );
      console.log(GetDataOfJSON(request, "data", 0, "JSONData", 0));
      let results = GetDataOfJSON(request, "data", 0, "JSONData", 0);

      let recordResult = results.JSONData || [];
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];

      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });
      // console.log("model", model)
      await bindDataCountRemote(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const reservationColor = (item) => {
    return {
      // backgroundColor: item.ReservationStatusBackgroundColor,
      backgroundColor: item.ReservationStatusBackgroundColor,
      color: item.ReservationStatusTextColor,
    };
  };

  const handleFilter = async (obj) => {
    console.log("🚀 ~ handleFilter ~ obj:", obj)
    setObjSPVersion(obj);
    await bindDataRemote(obj);
  };

  const bindDataCountRemote = async (filter) => {
    // First start loading the model
    // setLoading(true);

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(
        `api/GroupReservations/ReservationCount?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  // EVENTS
  // useEffect(() => {
  //   bindDataRemote();
  // }, []);

  useEffect(() => {
    bindDataRemote();
    setSelectedItem(null);
  }, [currentPage, sortBy, sortByOrder, RecordsQuantity]);

  useEffect(() => {
    console.log(showAddEdit);
    if (!showAddEdit) {
      bindDataRemote();
    }
  }, [showAddEdit]);

  useEffect(() => {
    if (reloadData) {
      bindDataRemote();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    if (!showCancelReservation || !showUnCancelReservation) {
      bindDataRemote();
    }
  }, [showCancelReservation, showUnCancelReservation]);

  useEffect(() => {
    setTotalPages(Math.ceil(recordCount / RecordsQuantity));
  }, [recordCount, RecordsQuantity]);

  return (
    <>
      <GroupReservationFilter
        count={recordCount}
        filterBy={bindDataRemote}
        handleFilter={(obj) => handleFilter(obj)}
        isLoading={isLoading}
      />
      <Card>
        <Card.Body>
          <div className="table-responsive">
            <table className="table table-bordered shadow-sm table-striped">
              <thead>
                <tr>
                  {headerWithoutAuditFields().map((item, index) => {
                    // console.log(item);
                    return (
                      <th
                        key={index}
                        className={`fw-bold clickable`}
                        onClick={() => {
                          // console.log(item, sortBy, sortByOrder)
                          setSortBy(item.FieldName);
                          if (item.FieldName === sortBy) {
                            if (sortByOrder === "ASC") {
                              setSortByOrder("DESC");
                            } else {
                              setSortByOrder("ASC");
                            }
                          } else {
                            setSortByOrder("ASC");
                          }
                        }}
                      >
                        {item.FieldTitle}{" "}
                        {sortBy === item.FieldName && sortByOrder === "ASC" ? (
                          <i className="fas fa-sort-up"></i>
                        ) : sortBy === item.FieldName &&
                          sortByOrder === "DESC" ? (
                          <i className="fas fa-sort-down"></i>
                        ) : null}{" "}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {model?.data && model?.data?.Rows.length > 0 ? (
                  model?.data?.Rows.map((item, index) => (
                    <tr
                      key={index}
                      className={`clickable ${
                        item === selectedItem ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedItem(item);
                      }}
                    >
                      <td>{item.ReservationGroupNumber || "N/A"}</td>
                      <td>{item.GroupName || "N/A"}</td>
                      <td>
                        {item.ArrivalDate
                          ? moment(item.ArrivalDate).format("MM/DD/YYYY")
                          : null}
                      </td>
                      <td>
                        {item.DepartureDate
                          ? moment(item.DepartureDate).format("MM/DD/YYYY")
                          : null}
                      </td>
                      <td>{item.Customer || "N/A"}</td>
                      <td>
                        <span
                          className="badge w-100"
                          style={reservationColor(item)}
                        >
                          {item.ReservationDescription}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="border-1 text-center" colSpan={7}>
                      {t("No Records Found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              isLoading={isLoading}
              RecordsQuantity={RecordsQuantity}
              recordCount={recordCount}
              // totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setRecordsQuantity={setRecordsQuantity}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default GroupReservationContainer;
