import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, ProgressBar, Row } from "react-bootstrap";

const RestaurantHoursAvailability = (props) => {
  const [hoursAvailables, setHoursAvailables] = useState([]);
  const calculatePercent = (quantity, maxQuantity) =>
    (quantity / maxQuantity) * 100;

  const saveHour = (item) => {
    console.log(item);
    props.updateFields("IdRestaurantSeatting", item.IdRestaurantSeatting);
  };
  useEffect(() => {
    // console.log(props);
    if (props.restaurantAvailability) {
      let available = props.restaurantAvailability;
      if (
        props.selectedReservation &&
        props.selectedReservation.IdRestaurantSeatting
      ) {
        available = available.map((item) => {
          console.log(item);
          if (
            item.IdRestaurantSeatting ===
            props.selectedReservation.IdRestaurantSeatting
          ) {
            item.Apply = true;
          }
          return item;
        });
      }
      setHoursAvailables(available);
    } else {
      setHoursAvailables([]);
    }
  }, [props.restaurantAvailability]);

  return (
    <>
      <Row className="mt-2">
        <Col xs={12}>
          <span>Restaurant hours Availability</span>
        </Col>
        <Col xs={12}>
          <div className="w-100">
            <div
              className="wrapper"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <table className="table table-bordered shadow-sm table-striped">
                <thead>
                  <tr>
                    <th className="sticky-col first-col">Select</th>
                    <th className="sticky-col second-col">Hour</th>
                    <th>Percent</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {hoursAvailables && hoursAvailables.length > 0 ? (
                    hoursAvailables.map((item, index) => {
                      let totalPercent = calculatePercent(
                        item.PaxTotal,
                        item.MaxPaxAllowed
                      ).toFixed(0);
                      let totalPlusPaxes =
                        props.paxes && props.paxes.length + item.PaxTotal;

                      return (
                        <tr key={index} className={`clickable`}>
                          <td className="text-center">
                            {totalPlusPaxes <= item.MaxPaxAllowed ? (
                              <input
                                type="radio"
                                name="hour"
                                checked={item.Apply}
                                onClick={() => saveHour(item)}
                              />
                            ) : (
                              <span>FULL</span>
                            )}
                          </td>
                          <td>
                            {moment(
                              props.selectedReservation.ReservationDate +
                                " " +
                                item.Hours
                            ).format("hh:mm A")}
                          </td>
                          <td>
                            <ProgressBar
                              style={{
                                backgroundColor: "#A9A9A9",
                                color: "red",
                              }}
                              min={0}
                              variant="info"
                              now={totalPercent}
                              max={item.MaxPaxAllowed}
                              label={`${totalPercent}%`}
                            />
                          </td>
                          <td>
                            {item.PaxTotal}/{item.MaxPaxAllowed}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="border-1 text-center" colSpan={12}>
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RestaurantHoursAvailability;
