// import './style.css';
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { ReservationsColorSet } from "./ReservationsColorSet";
import { RoomsColorSet } from "./RoomsColorSet";
import React, { useEffect, useState } from "react";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";

import { useTranslation } from "react-i18next";

export const ModalColorPicker = ({ show, handleClose }) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const [reservations, setReservations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const bindRemote = async () => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

    let request = await API.getAction(
      `api/Reservations/ReservationColorScheme`,
      queryString
    );
    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];
    // console.log(parsedResults)
    setReservations(parsedResults.ColorSchemeReservations);
    setRooms(parsedResults.ColorSchemeRooms);
  };

  useEffect(() => {
    bindRemote();
  }, []);

  return (
    <Modal show={show} onHide={() => handleClose()} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Color Scheme")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Card className="px-2 py-4">
          <Form>
            <Card.Body>
              <Form.Group controlId="exampleColorInput">
                <Row>
                  <Col md={6}>
                    <RoomsColorSet colorValues={rooms} />
                  </Col>
                  <Col md={6}>
                    <ReservationsColorSet colorValues={reservations} />
                  </Col>
                </Row>
              </Form.Group>
            </Card.Body>
          </Form>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
