import "./Header.scss";
import Button from "devextreme-react/button";
import { Template } from "devextreme-react/core/template";
import Toolbar, { Item } from "devextreme-react/toolbar";
import React, { useEffect, useState } from "react";
import UserPanel from "../user-panel/UserPanel";

import { DropDownButton } from "devextreme-react";
import { parsedUser } from "../../utils/GetCurrentUser";
import FavoritesBar from "../favorites-bar/FavoritesBar";

import { useTranslation } from "react-i18next";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import usePreventUnload from "../../hooks/usePreventUnload";
import ClearUser from "../../utils/ClearUser";
import ChangeOrganization from "./ChangeOrganization";

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const [user, setUser] = useState(parsedUser());
  const [showBusinessUnits, setShowBusinessUnits] = useState(false);
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [preventUnload, setPreventUnload] = useState(true);

  usePreventUnload(preventUnload, () => {
    // Tmeporary disable
    //   ClearUser()
  });

  const selectItem = async (item) => {
    setPreventUnload(false);
    try {
      const userToSend = user;
      userToSend.IdBusinessUnit = item.IdBusinessUnit;
      userToSend.NameBusinessUnit = item.BusinessUnit;
      const requestAPI = await API.postAction(
        `api/account/GeneratetokenWithId`,
        userToSend
      );

      localStorage.setItem("LogedUser", requestAPI.data.response);
      setTimeout(() => {
        window.location.reload();
      }, 100);
      setShowBusinessUnits(false);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
          width: 'auto',
          height: 'auto'
        })
        return;
      }
      toast({
        type: "error",
        message: 'Something went wrong while changing the organization',
        width: 'auto',
        height: 'auto'
      })
    } finally {
      setPreventUnload(true);
    }
  };

  const changeLanguage = (value) => {
    localStorage.setItem("lng", value);
    i18n.changeLanguage(value);
  };
  useEffect(() => {
    if (!showBusinessUnits) {
      setUser(parsedUser())
    }
  }
    , [showBusinessUnits])

  return (
    <header className={"header-component"}>
      {showBusinessUnits && <ChangeOrganization
        show={showBusinessUnits}
        toggle={() => setShowBusinessUnits(false)}
        setBusinessUnitSelected={selectItem}
      />}
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={"before"}


        >
          <h5 className="title-4 ps-4 clickable"
            onClick={() => setShowBusinessUnits(true)}
          >{user.NameBusinessUnit}
            <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
          </h5>
        </Item>

        <Item location={"before"}>
          <FavoritesBar />
        </Item>
        <Item location={"after"} locateInMenu={"auto"}>
          <DropDownButton
            className="language-select"
            icon="fa fa-language"
            text={t("Language")}
            onSelectionChanged={(e) => changeLanguage(e.item.lng)}
            items={[
              { id: 2, text: t("English"), lng: "en" },
              { id: 1, text: t("Spanish"), lng: "es" },
            ]}
          // Configuration goes here
          />
        </Item>
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            // width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
}
