import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../breadcrumb";
import { Button, Card, Container, Form, Table } from "react-bootstrap";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { GenericFields } from "../group-reservation/GenericFields";

import { AuditFields } from "./fields/AuditFields";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { GetDataOfJSON } from "../../utils/GetDataOfJSON";
import { useToast } from "../../contexts/toast";
import { confirmAlert } from "react-confirm-alert";
import Walks from "../Walks/Walks";

const NightClosingContainer = ({ routeName }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [alReadyRun, setAlReadyRun] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState(AuditFields);
  const [validationsSchema, setValidationSchema] = useState(null);

  const [isInProcess, setIsInProcess] = useState(false);
  const [records, setRecords] = useState([]);
  const [dataFields, setDataFields] = useState({});
  const [printNightClosing, setPrintNightClosing] = useState(false);

  console.log(dataFields);

  const user = parsedUser();

  const tasks = [
    {
      step: 1,
      title: t("Housekeeping Status Update"),
      description: "",
      status: false,
    },
    { step: 2, title: t("Noshows Update"), description: "", status: false },
    {
      step: 3,
      title: t("Cancelled Reservations Update"),
      description: "",
      status: false,
    },
    {
      step: 4,
      title: t("Daily Rates Application"),
      description: "",
      status: false,
    },
    {
      step: 5,
      title: t("Reservations Rate Amont Update"),
      description: "",
      status: false,
    },
    {
      step: 6,
      title: t("Posting Services"),
      description: "",
      status: false,
    },
    {
      step: 7,
      title: t("Setting Up Sytem Date"),
      description: "",
      status: false,
    },
  ];

  console.log(dataFields);

  const RequestStart = async () => {
    try {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: "@IdBussinessUnit=" + user.IdBusinessUnit,
      };

      let requestAPI = await API.postAction(
        "api/NightAudit/InitialValues?" + queryString,
        queryData
      );

      if (requestAPI.status === 200) {
        let result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
        console.log("Data", result.Data);
        setDataFields(result.Record[0]);
        setRecords(result.Data);

        console.log(result.Record[0]);
        console.log(result.Data);

        setIsInProcess(!isInProcess);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateStepsProcess = async () => {
    try {
      let allSteps = [
        { id: 1, endpoint: "p1_HouseKeeping" },
        { id: 2, endpoint: "p2_NoShows" },
        { id: 3, endpoint: "p3_CancelledReservations" },
        { id: 4, endpoint: "p4_DailyRates" },
        { id: 5, endpoint: "p5_ReservationsRateAmount" },
        { id: 6, endpoint: "p6_PostingServices" },
        { id: 7, endpoint: "p7_SettingUpSystem" },
      ];

      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@GUIDAudit="${dataFields.GUIDAudit}"`,
      };
      //      console.log("check what comes", dataFields);

      setIsLoading(true);

      for (const element of allSteps) {
        let requestAPI = await API.postAction(
          `api/NightAudit/${element.endpoint}?${queryString}`,
          queryData
        );
        console.log(requestAPI);
        if (requestAPI.status === 200) {
          let result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
          console.log(result);
          setDataFields(result.Record[0]);
        }
      }
      setAlReadyRun(true);
      toast({
        type: "success",
        message: t("Process completed"),
      });
    } catch (error) {
      RequestStart();
      setIsLoading(false);
      console.error(error);
    }
  };

  const SaveComment = async (obj) => {
    try {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@Comment='${obj.Comment}',@GUIDAudit='${dataFields.GUIDAudit}'`,
      };

      console.log(queryData);

      if (obj.Comment && obj.Comment?.length > 0) {
        let requestAPI = await API.postAction(
          "api/NightAudit/AddComment?" + queryString,
          queryData
        );

        if (requestAPI.status === 200) {
          let result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);

          toast({
            type: "success",
            message: t("Comment saved successfully"),
          });

          console.log(result);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (obj, event) => {
    const ValidateCheckIn = records.filter(
      (x) => x.Code === "I" && x.Quantity > 0
    )[0];
    const ValidateCheckOut = records.filter(
      (x) => x.Code === "O" && x.Quantity > 0
    )[0];
    let checkIn = ValidateCheckIn;
    let checkOut = ValidateCheckOut;
    // debugger;
    if (checkOut) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t(
          "Check-outs pending"
        ),
        buttons: [
          {
            label: t("Ok"),
          },
        ],
      });
      return;
    } else {
      let result = confirmAlert({
        closeOnClickOutside: false,
        message: checkIn
          ? t(
              "Check-ins pending will change to No Show"
            )
          : t("Are you sure you want to continue?"),
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await SaveComment(obj);
              await UpdateStepsProcess();
              setIsLoading(false);
            },
          },
          {
            label: t("No"),
          },
        ],
      });

      console.log(result);
    }
  };

  useEffect(() => {
    prepareFormikObject();
  }, [fields]);

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    // console.log("Preparing formik object", section);
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        // if (item.FieldName === "PolicyType" && customGridRecordsLength > 0) {
        //   // console.log(customGridRecordsLength);
        //   item.ReadOnly = true;
        // }
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const formik = useFormik({
    initialValues: dataFields,
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <CustomBreadcrumbs pathTitle={routeName} />
      <Container className="mt-0 pt-0 mh-100">
        <div className="row mx-3">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="dx-icon fa-solid fa-moon me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{t("Night Audit")}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>

            {isInProcess ? (
              <>
                <Button
                  form="thisForm"
                  variant="outline-dark"
                  size="lg"
                  type="submit"
                  disabled={isLoading || alReadyRun}
                >
                  {t("Run Process")}
                  <br />
                  <i className={"fa-solid fa-gears"} />
                </Button>
              </>
            ) : (
              <Button
                variant="outline-success"
                size="lg"
                onClick={() => {
                  setIsLoading(true);
                  RequestStart();
                }}
              >
                {t("Start Process")}
                <br />
                <i className={"fa-solid fa-play"} />
              </Button>
            )}

            <hr />
          </div>

          <div className="col-6">
            <Form onSubmit={formik.handleSubmit} id="thisForm">
              <div className="row">
                <GenericFields
                  fields={fields}
                  values={formik.values}
                  errors={formik.errors}
                  touched={formik.touched}
                  updateFields={updateField}
                />

                <div className="col-12 mt-3">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>{t("Description")}</th>
                        <th>{t("Quantity")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records && records.length > 0 ? (
                        records.map((item, index) => (
                          <tr key={index}>
                            <td>{item.Description}</td>
                            <td>{item.Quantity}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="border-1 text-center" colSpan={2}>
                            {t("No Records Found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-6 ">
            <Card style={{ backgroundColor: "#F4F4F4" }}>
              <Card.Header>
                <Card.Title>{t("Tasks")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Container fluid className="d-flex overflow-auto">
                  <Walks steps={tasks} stepLimit={dataFields.LastAuditStep} />
                </Container>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NightClosingContainer;
