import { Col } from "react-bootstrap";
import FieldSelector from "../customComponents/FieldSelector";
import { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";

export const GenericFields = ({
  fields,
  values,
  touched,
  errors,
  updateFields,
  customLabel,
  section,
}) => {
  const { t } = useTranslation();
  // console.log("fields", fields, "values", values, "touched", touched);

  useEffect(() => {
    console.log("values", values);
  }, [values]);
  return (
    <>
      {fields.map((item, index) => {
        // console.log(item.FieldName, values[item.FieldName]);
        return (
          <Fragment key={index}>
            {customLabel && customLabel.status ? (
              //This code fragment needs to be improved. Due to lack of time it was not done.
              <Col className={`${customLabel.className}`}>
                <label
                  className={`${
                    item.FieldName === "paxQuantity"
                      ? customLabel.classNameLabel
                      : null
                  }`}
                >
                  {t(item.FieldTitle)}
                </label>
                {item.Required ? (
                  <i className="required-asterisk ms-1 text-danger">*</i>
                ) : null}
              </Col>
            ) : null}

            <Col
              className={
                item.cssClasss +
                (!item.Visible &&
                item.FieldName !== "Active" &&
                item.FieldName !== "ExecutionDate"
                  ? " d-none"
                  : "")
              }
            >
              {customLabel && customLabel.status ? null : (
                <>
                  <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                  {item.Required ? (
                    <i className="required-asterisk ms-1 text-danger">*</i>
                  ) : null}
                  <br />
                </>
              )}

              <FieldSelector
                // actionMode={actionMode}
                section={section}
                model={item}
                key={index}
                obj={values ?? ""}
                updateField={(name, value, optionText) =>
                  updateFields(name, value, optionText)
                }
                value={values ? values[item.FieldName] ?? "" : ""}
              ></FieldSelector>
              {errors[item.FieldName] /* || touched[item.FieldName] */ ? (
                <div className="invalid text-sm">{errors[item.FieldName]}</div>
              ) : null}
            </Col>
          </Fragment>
        );
      })}
    </>
  );
};
