import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { RateInfoFields } from "../fields/RateInfoFields";
import moment from "moment";
import { GenericFields } from "../../group-reservation/GenericFields";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";

import { useTranslation } from "react-i18next";

export const RateInfoTab = ({
  errors,
  touched,
  updateFields,
  values,
  guestTypes,
  toggleRateDetails,
  actionMode,
  isLoading,
  searchRate,
  onBlurUpdateField,
  setFieldError,
  categoryCodeSelected,
}) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const toast = useToast();
  const [fields, setFields] = useState(RateInfoFields);
  const [rateCategoryTypes, setRateCategoryTypes] = useState([]);
  const [selectedRateCategoryType, setSelectedRateCategoryType] =
    useState(null);

  useEffect(() => {
    requestRateCategoryTypes();
  }, []);

  useEffect(() => {
    let fieldsObj = fields;
    const selectedRateCategoryType = rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0]
    // if (values.IdRateCategoryType) {
    // }
    if (selectedRateCategoryType) {
      switch (selectedRateCategoryType?.Code) {
        case "P": //Complementary
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("TotalStay", 0);
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "C": //Contract
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "H": // House Use
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("TotalStay", 0);
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "M": // Rate assigned
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = true;
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        default:
          break;
      }
    }
  }, [])

  useEffect(() => {
    if (guestTypes.length > 0 && actionMode === "U") {

      let fieldsObj = fields;
      const selectedRateCategoryType = rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0]
      // if (values.IdRateCategoryType) {
      // }
      if (selectedRateCategoryType) {
        switch (selectedRateCategoryType?.Code) {
          case "P": //Complementary
            fieldsObj = fieldsObj.map((item) => {
              if (item.FieldName === "RateAssigned") {
                item.Visible = false;
                updateField("TotalStay", 0);
                updateField("RateAssigned", 0);
              }
              return item;
            });
            setFields(fieldsObj);
            break;
          case "C": //Contract
            fieldsObj = fieldsObj.map((item) => {
              if (item.FieldName === "RateAssigned") {
                item.Visible = false;
                updateField("RateAssigned", 0);
              }
              return item;
            });
            setFields(fieldsObj);
            break;
          case "H": // House Use
            fieldsObj = fieldsObj.map((item) => {
              if (item.FieldName === "RateAssigned") {
                item.Visible = false;
                updateField("TotalStay", 0);
                updateField("RateAssigned", 0);
              }
              return item;
            });
            setFields(fieldsObj);
            break;
          case "M": // Rate assigned
            fieldsObj = fieldsObj.map((item) => {
              if (item.FieldName === "RateAssigned") {
                item.Visible = true;
              }
              return item;
            });
            setFields(fieldsObj);
            break;
          default:
            break;
        }
      }
      //execute DBSignumPMS_DEV.pms.spReservations @IdActiveUser=6, @Accion='U', @Token=161847418, @IdParent=null, @IdBusinessUnit=6, @IdBillingBusinessUnit=6, @IdRoom=null, @IdCustomer=100, @IdCurrency=1, @IdFolioType=null, @IdReservationType=null, @IdRoomType=20, @IdMealPlan=1, @IdReservationsStatus=2, @IdRateCategoryType=1, @ReservationNumber=1236, @ArrivalDate='2024-11-17', @DepartureDate='2024-11-22', @Nights=5, @TransactionDate='2024-11-14T03:19:44.673', @ConfirmationNumber=null, @BookingDate='2024-11-13', @BookingNumber='1234', @MemberName=null, @Membership=null, @Blocked='false', @CreditLimit=null, @CancelationNumber=null, @CancelDescription=null, @Voucher=null, @CreditCardNumber=null, @ExpirationDate=null, @AdultQuantity=2, @SeniorQuantity=0, @Child1Quantity=0, @Child2Quantity=0, @Child3Quantity=0, @InfantQuantity=0, @IdSpecialCode=null, @RateCode=null, @RateAssigned=0, @DailyRate=60, @TotalStay=300, @AmountAdult=300, @AmountSenior=0, @AmountChildren1=0, @AmountChildren2=0, @AmountChildren3=0, @AmountInfant=0, @IdBuilding=null, @IdBuildingFloor=null, @IdRoomView=null, @EvolutionReservationType=null, @EvolutionRequester=null, @EvolutionMembershipType=null, @EvolutionMemberNumber=null, @Active='true', @IdClientAdd=null, @GUID='22574CE9-A823-4201-9215-BBA81590D425', @Room=null, @ReservationGroupNumber=70, @GroupName='fsfdsfdsf'

      // console.log("🚀 ~ useEffect ~ values", values);
      // console.log({TIPOCATEGORIA: 
      //   rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0].Code
      // });
      if (rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0]) {
        categoryCodeSelected(rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0].Code);
        toast({
          title: t("Warning"),
          message: t("Changes made at Rate Info affect the rate."),
          type: "warning",
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
      }
    }
  }, [guestTypes, actionMode]);

  const requestRateCategoryTypes = async (id) => {
    try {
      let query = `token=${user.Token}&id=97cf9a0606f82ed6e8885961e5fbce5cdd82909e2343e3f5b2e38fc31807ae95`;
      var request = await API.getAction("api/selector", query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        console.error(parsedResults.Msg);
        return;
      }
      let recordResult = JSON.parse(parsedResults.JSONData);
      if (recordResult) {
        setRateCategoryTypes(recordResult);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateField = async (fieldName, fieldValue, dontUpdate) => {
    console.log("🚀 ~ updateField ~ selectedRateCategoryType:", selectedRateCategoryType)
    if (fieldName === 'BookingNumber') {
      debugger;
    }
    categoryCodeSelected(rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0]?.Code);
    console.log("🚀 ~ updateField ~ rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0].Code:", rateCategoryTypes.filter((x) => x.Id === values.IdRateCategoryType)[0]?.Code)
    if (fieldValue === "Invalid date") {
      fieldValue = null;
    }
    if (
      selectedRateCategoryType?.Code === "M" &&
      fieldName === "RateAssigned"
    ) {
      onBlurUpdateField(fieldName, fieldValue);
    }

    if (fieldName === "IdRateCategoryType") {
      console.log(rateCategoryTypes.filter(x => x.Id === fieldValue)[0]?.Code);
      setSelectedRateCategoryType(
        rateCategoryTypes.filter((x) => x.Id === fieldValue)[0]
      );
      // categoryCodeSelected(
      //   rateCategoryTypes.filter((x) => x.Id === fieldValue)[0]
      // );
    }

    // Reset rate room type if IdBillingBusinessUnit is changed.
    if (fieldName === "IdBillingBusinessUnit") {
      updateFields("IdRoomType", "");
    }

    //Reset TotalStay if any of these is being updated
    if (
      (fieldName === "IdRoomType" ||
        fieldName === "IdCustomer" ||
        fieldName === "DepartureDate" ||
        fieldName === "Nights" ||
        fieldName === "ArrivalDate" ||
        fieldName === "IdMealPlan") &&
      guestTypes.length > 0
    ) {
      // toast({
      //   title: "Warning",
      //   message: "Any changes made may affect the rate.",
      //   type: "warning",
      // });
      setFieldError(
        "TotalStay",
        t("Any changes made at Rate Info may affect the rate.")
      );
      setTimeout(() => {
        setFieldError("TotalStay", "");
      }, 5500);
      updateFields("TotalStay", null);
    }

    // check if arrivalDate is greater than departure date
    if (fieldName === "ArrivalDate" && values.DepartureDate && !dontUpdate) {
      if (moment(fieldValue).isAfter(values.DepartureDate)) {
        updateFields("DepartureDate", fieldValue);
      }
    }

    // Check if arrivalDate change and nights is set should update the departure date by adding nights.
    if (fieldName === "ArrivalDate" && values.Nights && !dontUpdate) {
      let addDays = moment(fieldValue).add(values.Nights, "days");
      values.DepartureDate = addDays;
      updateFields("DepartureDate", addDays);
    }

    // Check if nights have change
    if (fieldName === "Nights" && values.ArrivalDate && !dontUpdate) {
      let addDays = moment(values.ArrivalDate).add(fieldValue, "days");
      values.DepartureDate = addDays;
      updateFields("DepartureDate", addDays);
    } else if (fieldName === "DepartureDate" && !dontUpdate) {
      let days = moment(fieldValue).diff(moment(values.ArrivalDate), "days");
      values.Nights = isNaN(days) ? 0 : days;
      updateFields("Nights", values.Nights);
    }

    values[fieldName] = fieldValue;
    // console.log(fieldName, fieldValue);
    updateFields(fieldName, fieldValue);
  };

  useEffect(() => {
    if (selectedRateCategoryType) {
      let fieldsObj = fields;
      //formik.setFieldValue("IdRateCategoryType", selectedRateCategoryType.Id);

      switch (selectedRateCategoryType.Code) {
        case "P": //Complementary
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("TotalStay", 0);
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "C": //Contract
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "H": // House Use
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = false;
              updateField("TotalStay", 0);
              updateField("RateAssigned", 0);
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        case "M": // Rate assigned
          fieldsObj = fieldsObj.map((item) => {
            if (item.FieldName === "RateAssigned") {
              item.Visible = true;
            }
            return item;
          });
          setFields(fieldsObj);
          break;
        default:
          break;
      }
      // console.log("change", selectedRateCategoryType, fieldsObj);
    } else {
      setSelectedRateCategoryType(
        rateCategoryTypes.filter((x) => x.Id === values?.IdRateCategoryType)[0]
      );
      // console.log("🚀 ~ useEffect ~ rateCategoryTypes", rateCategoryTypes, values?.IdRateCategoryType)
    }
  }, [selectedRateCategoryType]);

  return (
    <>
      <Row className="mx-0">
        <Col xs={12} md={6}>
          <Row>
            <GenericFields
              fields={fields.filter((x) => x.GroupBy === 0)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
          {/* TABLE OF GUEST TYPES */}
          <table className="table table-bordered table-striped mt-4">
            <thead>
              <tr>
                <th>{t("Guest Type")}</th>
                <th>{t("Quantity")}</th>
              </tr>
            </thead>
            <tbody>
              {guestTypes &&
                guestTypes.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.GuestType}</td>
                      <td>{item.Quantity}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Row>
            <GenericFields
              fields={fields
                .map((x) => {
                  if (x.FieldName === "TotalStay") {
                    return (x.ReadOnly = true);
                  }
                  if (actionMode === "VIEW") {
                    return (x.ReadOnly = true);
                  }
                  if (actionMode === "K" && x.FieldName !== "DepartureDate") {
                    return (x.ReadOnly = true);
                  } else {
                    return (x.ReadOnly = false);
                  }
                })
                .filter((x) => x.GroupBy === 3)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <GenericFields
              fields={fields.filter((x) => x.GroupBy === 1)}
              values={values}
              touched={touched}
              errors={errors}
              updateFields={updateField}
            />
          </Row>
          <div className="d-flex justify-content-end mt-4">
            {
              // console.log(values)
            }
            <button
              className="btn btn-outline-secondary me-2"
              disabled={isNaN(values?.TotalStay)}
              type="button"
              onClick={() => toggleRateDetails()}
            >
              <i className="fa-regular fa-rectangle-list me-2"></i>
              {t("Stay Details")}
            </button>
            <button
              type="button"
              disabled={
                actionMode === "K" ||
                isLoading ||
                selectedRateCategoryType?.Code === "M" ||
                actionMode === "VIEW"
              }
              className="btn btn-outline-primary "
              onClick={searchRate}
            >
              <i className="fa-solid fa-calculator me-2"></i>
              {t("Calculate")}
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};
