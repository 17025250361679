import "../../reservations/guest-reservation-custom-styles.css";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { GenericFields } from "../../group-reservation/GenericFields";
import { handleFilter } from "../../../utils/filter-utils";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { RoomOverviewFilterFields } from "../fields/RoomOverviewFilterFields";
import { Search } from "@mui/icons-material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import API from "../../../api/api";
import React, { useEffect, useState } from "react";
import RoomOverviewReservationStatusFilter from "./RoomOverviewReservationStatusFilter";
import RoomOverviewRoomStatusFilter from "./RoomOverviewRoomStatusFilter";
import yupTypeValidator from "../../../utils/YupTypeValidator";

const RoomOverviewFilter = ({ filterRecord, count }) => {
  const user = parsedUser();
  const { t } = useTranslation();
  const [fields, setFields] = useState(RoomOverviewFilterFields);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [RoomStatusFilter, setRoomStatusFilter] = useState({});
  const [ReservationStatusFilter, setReservationStatusFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (formikObj) => {
    const obj = {
      ...formikObj,
      ...RoomStatusFilter,
      ...ReservationStatusFilter,
    };
    const objSPVersion = handleFilter(obj, fields);
    filterRecord(objSPVersion);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };
  useEffect(() => {
    if (validationsSchema) {
      // debugger;
      updateField("IdBusinessUnit", +user?.IdBusinessUnit );
    }
  }, [validationsSchema]);

  const updateField = (fieldName, fieldValue) => {
    if (fieldName === "Room") {
      fieldValue = fieldValue.replace(/[^a-zA-Z0-9-]/g, "");
      if (fieldValue === "") {
        fieldValue = null;
      }
    }
    formik.setFieldValue(fieldName, fieldValue);
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Fetches a list of records from the ChangeBusinessUnit SP.
   * The records are used to populate the business unit dropdown.
   * @return {Promise<void>}
   */
  /******  e9731293-9418-4030-8d02-d8602783bac0  *******/
  const requestOrganizations = async () => {
    // console.log("🚀 ~ requestOrganizations ~ user.IdBusinessUnit:", user.IdBusinessUnit)
    setIsLoading(true);
    try {
      const queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      const requestAPI = await API.postAction(
        `api/account/ChangeBusinessUnit?${queryString}`,
        { Data: null }
      );
      if (requestAPI.data) {
        const data = requestAPI.data[0].JSONData;
        const parsed = JSON.parse(data);
        // console.log("🚀 ~ requestOrganizations ~ parsed[0].Records:", parsed[0].Records)
        setRecords(parsed[0].Records);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (records.length > 0) {
      insertFieldOptionValues("IdBusinessUnit", records);
    }
  }, [records]);

  const insertFieldOptionValues = (fieldName, OptionValues = []) => {
    let records = OptionValues.map((item) => {
      return {
        id: item.IdBusinessUnit,
        text: item.BusinessUnit,
      };
    });
    const field = fields.find((item) => item.FieldName === fieldName);
    if (field) {
      field.OptionValues = JSON.stringify(records);
      setFields([...fields]);
    }
  };

  useEffect(() => {
    requestOrganizations();
  }, []);

  return (
    <Accordion className="mb-3 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0">
          <span className="h5 m-0" style={{ fontSize: "14px" }}>
            {t("Filter Assigment")}
          </span>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-0">
          <Card className="w-100">
            <Form onSubmit={formik.handleSubmit}>
              <Card.Body className="bg-light">
                <Row className="mx-0">
                  <Col xs={12} md={7}>
                    <Row>
                      <GenericFields
                        errors={formik.errors}
                        values={formik.values}
                        updateFields={updateField}
                        fields={fields.filter((item) => item.Group === 0)}
                      />
                    </Row>
                  </Col>
                  <Col xs={6} md={2}>
                    <Row>
                      <RoomOverviewReservationStatusFilter
                        filterBy={(data) => setReservationStatusFilter(data)}
                      />
                    </Row>
                  </Col>
                  <Col xs={6} md={3}>
                    <Row>
                      <RoomOverviewRoomStatusFilter
                        filterBy={(data) => setRoomStatusFilter(data)}
                      />
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <div className="display-6 text-success">
                      {t("Rooms found:")} {count || 0}
                    </div>
                  </Col>
                  <Col xs="auto text-end">
                    <button
                      className="btn btn-sm btn-secondary me-2"
                      type="button"
                      title={t("Clear")}
                      onClick={() => formik.resetForm()}
                    >
                      <i className="fas fa-eraser"></i>
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      title={t("Search")}
                      type="submit"
                      disabled={isLoading}
                    >
                      <Search />
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default RoomOverviewFilter;
