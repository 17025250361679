import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CheckBox } from "devextreme-react";

import { useTranslation } from "react-i18next";

/**
 * 
,@FilterAllReservation bit = 1 
,@FilterArrival bit = 0 
,@FilterStayover bit = 0 
,@FilterDeparture bit = 0 
 */

export const getIconsByType = (item) => {
  const icons = {
    FilterArrival: ["fa-solid fa-arrow-right-to-bracket"],
    FilterStayover: ["fa-solid fa-person-arrow-down-to-line"],
    FilterDeparture: ["fa-solid fa-door-open"],
  };
  return icons[item.name] || [];
};

const RoomOverviewReservationStatusFilter = ({ filterBy }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    FilterAllReservation: true,
    FilterArrival: true,
    FilterStayover: true,
    FilterDeparture: true,
  });

  useEffect(() => {
    filterBy(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleFilter = (name, checked) => {
    // const { name, checked } = e.target

    // Select all
    if (name === "FilterAllReservation") {
      setFilter({
        FilterAllReservation: checked,
        FilterArrival: checked,
        FilterStayover: checked,
        FilterDeparture: checked,
      });
      return;
    }

    // Unselect 'all' if any other is unselected
    if (name !== "FilterAllReservation" && filter.FilterAllReservation) {
      setFilter({
        ...filter,
        FilterAllReservation: false,
        [name]: checked,
      });
      return;
    }

    // select all if all are selected and the default case.
    setFilter((prev) => {
      const updatedFilter = {
        ...prev,
        [name]: checked,
      };

      if (
        name !== "FilterAllReservation" &&
        Object.keys(updatedFilter)
          .filter((key) => key !== "FilterAllReservation")
          .every((key) => updatedFilter[key]) &&
        !updatedFilter.FilterAllReservation
      ) {
        updatedFilter.FilterAllReservation = true;
      }

      return updatedFilter;
    });
  };

  const Checkboxes = [
    {
      label: t("All"),
      name: "FilterAllReservation",
    },
    {
      label: t("Arrival"),
      name: "FilterArrival",
    },
    {
      label: t("In House"),
      name: "FilterStayover",
    },
    {
      label: t("Departure"),
      name: "FilterDeparture",
    },
  ];

  return (
    <Row className="mx-0 p-2">
      <Col>
        {Checkboxes.map((item, index) => (
          <Col key={index}>
            <Row>
              <Col className="d-flex gap-2" xs={8}>
                <label className="me-2 mt-2">{t(item.label)}</label>
                {getIconsByType(item).map((icon, index) => (
                    <i key={index} className={`fs-4 mx-2 ${icon}`} />
                  ))}
              </Col>
              <Col className="d-flex justify-content-end" xs={4}>
                <CheckBox
                  // className='mt-2'
                  name={item.name}
                  value={filter[item.name]}
                  onValueChange={(e) => {
                    handleFilter(item.name, e);
                  }}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Col>
    </Row>
  );
};

export default RoomOverviewReservationStatusFilter;
