import { t } from "i18next";

const VirtualRoomFields = [
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Name",
    FieldType: "bigint",
    FieldTitle: t("Name"),
    Position: 1,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 19,
    Id: 1901003,
    FieldName: "IdCustomerType",
    FieldType: "bigint",
    FieldTitle: t("Customer Type"),
    Position: 2,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountry",
    FieldType: "bigint",
    FieldTitle: t("Country"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdParent",
    FieldType: "bigint",
    FieldTitle: t("Parent"),
    Position: 4,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountryState",
    FieldType: "bigint",
    FieldTitle: t("State"),
    Position: 5,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Address1",
    FieldType: "bigint",
    FieldTitle: t("Address 1"),
    Position: 6,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Address2",
    FieldType: "bigint",
    FieldTitle: t("Address 2"),
    Position: 7,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "City",
    FieldType: "bigint",
    FieldTitle: t("City"),
    Position: 8,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "ZipCode",
    FieldType: "bigint",
    FieldTitle: t("Zip Code"),
    Position: 9,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "WebSite",
    FieldType: "bigint",
    FieldTitle: t("Web Site"),
    Position: 10,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export const VirtualRoomFilterSet = [
  {
    FieldName: "Code",
    FieldType: "varchar",
    FieldTitle: t("Code"),
    cssClasss: "col-6 col-md-3",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    cssClasss: "col-6 col-md-3",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 19,
    Id: 1901003,
    FieldName: "IdRoomType",
    FieldType: "bigint",
    FieldTitle: t("Room Type"),
    Position: 2,
    cssClasss: "col-6 col-md-3",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"c8cd1c30a5cd65d35b2dc5b4c0287d131a741f68a85fd9828e4216931f6ff384"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 19,
    Id: 1901003,
    FieldName: "IdBusinessUnit",
    FieldType: "bigint",
    FieldTitle: t("Business Unit"),
    Position: 2,
    cssClasss: "col-6 col-md-3",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
]

export default VirtualRoomFields;
